import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  DrawingManager,
  useLoadScript,
} from "@react-google-maps/api";
import * as XLSX from "xlsx";
import {
  LinearProgress,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
  Collapse,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  Grid,
  IconButton,
} from "@mui/material";
import { db } from "../../firebase";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { translateStatesfalabbela } from "../../utils/objects/translateStates";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import moment from "moment";
import mapStylesClear from "../MapsGoogle/mapStylesClear";
import mapStyleCoolGrey from "../MapsGoogle/mapStyleCoolGrey";
import EditIcon from "@mui/icons-material/Edit"; // Icono para el botón Editar
import SelectorGeoRecf from "../Dispatchdetails/SelectorGeoRecf";
import { GeoPoint } from "firebase/firestore";

const itemsPerPage = 10;

const MapComponentDistributions = ({ dataMerge, ridersDataByCompany , companyID }) => {
 
  const memoizedLibraries = useMemo(
    () => ["geometry", "drawing", "places"],
    []
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries: memoizedLibraries,
  });

  const [center] = useState({ lat: -33.448891, lng: -70.669266 });
  const [zoom] = useState(13);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [expandedRoutes, setExpandedRoutes] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [targetRoute, setTargetRoute] = useState("");
  const [sortCriteria, setSortCriteria] = useState("");
  const [selectMode, setSelectMode] = useState(""); // 'single', 'lasso', 'polygon'
  const [polygonOptions, setPolygonOptions] = useState([]); // Predefined polygons
  const [drawingManager, setDrawingManager] = useState(null);
  const mapRef = useRef(null);
  const [isFilterMode, setIsFilterMode] = useState(true); // true for "Solo Filtrar", false for "Seleccionar"

  // Estado local para las órdenes y rutas
  const [orders, setOrders] = useState([]);
  const [routes, setRoutes] = useState([]); // Lista de rutas existentes
  const [routeColors, setRouteColors] = useState({}); // Colores asignados a rutas
  const colorPalette = [
    "blue",
    "red",
    "green",
    "orange",
    "purple",
    "pink",
    "cyan",
    "magenta",
    "yellow",
    "brown",
    "teal",
    "lime",
    "indigo",
    "violet",
    "navy",
    "maroon",
    "olive",
    "chocolate",
    "coral",
    "crimson",
    "darkblue",
    "darkgreen",
    "darkmagenta",
    "darkorange",
    "darkred",
    "darkcyan",
    "darkviolet",
    "deepskyblue",
    "dodgerblue",
    "gold",
    "hotpink",
    "khaki",
    "lightcoral",
    "lightgreen",
    "lightsalmon",
    "lightskyblue",
    "lightseagreen",
    "mediumorchid",
    "mediumspringgreen",
    "peru",
    "plum",
    "rosybrown",
    "salmon",
    "sienna",
    "slateblue",
    "steelblue",
    "tomato",
    "tan",
    "thistle",
    "turquoise",
  ];
  // Estado para manejar el diálogo de agregar ruta
  const [openAddRouteDialog, setOpenAddRouteDialog] = useState(false);
  const [newRouteName, setNewRouteName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isChangeMode, setIsChangeMode] = useState(false);

  //Botón "Filtrar por estados":
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  // "Optimizar":
  const [openOptimizeDialog, setOpenOptimizeDialog] = useState(false);
  const [optimizeOptions, setOptimizeOptions] = useState({
    retornable: false,
    fan: false,
    origen: "",
    destino: "",
    provider: "Bicci",
    selector: "Todos", // "Todos" o "Seleccionados"
    createRequireVehicles: true,
    vehicleCount: 1,
    createRequireAgrupations: true,
    agrupationCount: 1,
  });

  // "Guardar documento";
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [documentData, setDocumentData] = useState({
    name: "",
    date: new Date().toISOString().split("T")[0],
    autoAssignDrivers: false,
    routeDrivers: {},
  });

  // Estados para el diálogo de planes guardados
  const [openPlansDialog, setOpenPlansDialog] = useState(false);
  const [plans, setPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [optionsMap, setOptionMap] = useState(null);

  // Estado para resaltar pines: hoveredOrderId
  const [hoveredOrderId, setHoveredOrderId] = useState(null);

  // Estado para el diálogo de edición de una orden
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editOrder, setEditOrder] = useState(null); // Orden que se está editando

  const [newLocation, setNewLocation] = React.useState(null);

  const options = {
    styles: optionsMap,
    disableDefaultUI: true,
    zoomControl: true,
    zoom: 14,
    gestureHandling: "greedy", // Esta opción permite el zoom con la rueda del ratón sin presionar Ctrl
    scrollwheel: true, // Asegúrate de que scrollwheel esté en true
  };

  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  useEffect(() => {
    if (isChangeMode === false) {
      setOptionMap(null);
    } else {
      setOptionMap(mapStyleCoolGrey);
    }
  }, [isChangeMode]);

  useEffect(() => {
    // Inicializar orders con dataMerge
    if (dataMerge && dataMerge.length > 0) {
      setOrders(dataMerge);

      // Extraer rutas existentes de las órdenes
      const existingRoutes = new Set();
      dataMerge.forEach((order) => {
        const route = order.driverLicensePlate || "Sin Ruta";
        existingRoutes.add(route);
      });
      setRoutes(Array.from(existingRoutes));

      const statuses = Array.from(
        new Set(
          dataMerge.map((order) =>
            (order.falabella_status || "Sin estado").trim().toLowerCase()
          )
        )
      );
      setAvailableStatuses(statuses);
    }
  }, [dataMerge]);

  useEffect(() => {
    const updatedRouteColors = { ...routeColors }; // Mantener colores asignados previamente
    const availableColors = colorPalette.filter((color) => color !== "blue"); // Excluir azul

    let colorIndex = 0;

    routes.forEach((route) => {
      if (!updatedRouteColors[route]) {
        // Asignar color solo a las rutas nuevas
        const randomColor =
          availableColors[Math.floor(Math.random() * availableColors.length)];
          updatedRouteColors[route] = randomColor;

        // Eliminar el color usado de la paleta
        const usedColorIndex = availableColors.indexOf(randomColor);
        if (usedColorIndex > -1) {
          availableColors.splice(usedColorIndex, 1);
        }

        // Si se quedan sin colores disponibles, reinicia la paleta (sin incluir azul)
        if (availableColors.length === 0) {
          availableColors.push(
            ...colorPalette.filter((color) => color !== "blue")
          );
        }
      }
    });

    if (!updatedRouteColors["Sin Ruta"]) {
      updatedRouteColors["Sin Ruta"] = "blue"; // Asegura que "Sin Ruta" siempre sea azul
    }

    setRouteColors(updatedRouteColors);
  }, [routes]);

  // Calcular información de rutas y órdenes
  const routeInfo = useMemo(() => {
    const packageCounts = {};
    const ordersByRoute = {};

    if (orders && orders.length > 0) {
      orders.forEach((order) => {
        const route = order.driverLicensePlate || "Sin Ruta";
        if (!packageCounts[route]) {
          packageCounts[route] = 0;
        }
        packageCounts[route] += 1; // Asume que cada pedido es un bulto

        if (!ordersByRoute[route]) {
          ordersByRoute[route] = [];
        }
        ordersByRoute[route].push(order);
      });
    }

    // Asegurarnos de que todas las rutas aparecen en ordersByRoute, incluso si no tienen órdenes
    routes.forEach((route) => {
      if (!ordersByRoute[route]) {
        ordersByRoute[route] = [];
        packageCounts[route] = 0;
      }
    });

    return { packageCounts, ordersByRoute };
  }, [orders, routes]);

  if (loadError) {
    return <div>Error al cargar el mapa</div>;
  }

  if (!isLoaded) {
    return <LinearProgress />;
  }

  const handleRouteSelection = (route) => {
    if (isFilterMode) {
      // Solo filtrar
      setSelectedRoutes((prevSelected) => {
        if (prevSelected.includes(route)) {
          return prevSelected.filter((r) => r !== route);
        } else {
          return [...prevSelected, route];
        }
      });
    } else {
      // Seleccionar
      setSelectedRoutes((prevSelected) => {
        if (prevSelected.includes(route)) {
          // Al deseleccionar una ruta, eliminamos sus órdenes de selectedOrders
          const ordersToRemove = routeInfo.ordersByRoute[route].map(
            (order) => order.id
          );
          setSelectedOrders((prevOrders) =>
            prevOrders.filter((id) => !ordersToRemove.includes(id))
          );
          return prevSelected.filter((r) => r !== route);
        } else {
          // Al seleccionar una ruta, añadimos sus órdenes a selectedOrders
          const ordersToAdd = routeInfo.ordersByRoute[route].map(
            (order) => order.id
          );
          setSelectedOrders((prevOrders) => [...prevOrders, ...ordersToAdd]);
          return [...prevSelected, route];
        }
      });
      handleRouteExpansion(route); // Expande al seleccionar
    }
  };

  const handleRouteExpansion = (route) => {
    setExpandedRoutes((prevExpanded) => {
      if (prevExpanded.includes(route)) {
        return prevExpanded.filter((r) => r !== route);
      } else {
        return [...prevExpanded, route];
      }
    });
  };

  const handleOrderSelection = (orderId) => {
    if (isFilterMode) return; // En modo solo filtrar no seleccionamos órdenes
    setSelectedOrders((prevSelected) => {
      if (prevSelected.includes(orderId)) {
        return prevSelected.filter((id) => id !== orderId);
      } else {
        return [...prevSelected, orderId];
      }
    });
  };

  const handleOrderDoubleClick = (orderId) => {
    handleOrderSelection(orderId);
  };

  const handleAddRoute = () => {
    // Abrir el diálogo para ingresar el nombre de la nueva ruta
    setOpenAddRouteDialog(true);
  };

  const handleAddRouteConfirm = () => {
    if (newRouteName.trim() === "") {
      alert("Por favor, ingresa un nombre para la nueva ruta.");
      return;
    }
    // Verificar si la ruta ya existe
    if (routes.includes(newRouteName)) {
      alert("La ruta ya existe. Por favor, ingresa un nombre diferente.");
      return;
    }
    // Agregar la nueva ruta al listado y asignar un color
    setRoutes((prevRoutes) => [...prevRoutes, newRouteName]);

    // Asignar color a la nueva ruta
    setRouteColors((prevColors) => ({
      ...prevColors,
      [newRouteName]:
        colorPalette[Object.keys(prevColors).length % colorPalette.length],
    }));

    // Cerrar el diálogo y limpiar el campo
    setNewRouteName("");
    setOpenAddRouteDialog(false);
  };

  const handleAddRouteCancel = () => {
    setNewRouteName("");
    setOpenAddRouteDialog(false);
  };

  const handleChangeRoute = () => {
    // Lógica para cambiar órdenes seleccionadas a otra ruta
    if (!targetRoute) {
      alert("Por favor, selecciona una ruta de destino.");
      return;
    }
    if (selectedOrders.length === 0) {
      alert("No hay órdenes seleccionadas.");
      return;
    }
    // Actualizar orders con las órdenes movidas
    const updatedOrders = orders.map((order) => {
      if (selectedOrders.includes(order.id)) {
        return { ...order, driverLicensePlate: targetRoute };
      }
      return order;
    });
    setOrders(updatedOrders);
    // Limpiar selección
    setSelectedOrders([]);
    setSelectedRoutes([]);
    setTargetRoute("");
  };

  const handleRemoveFromRoute = () => {
    // Lógica para sacar las órdenes seleccionadas de su ruta actual
    if (selectedOrders.length === 0) {
      alert("No hay órdenes seleccionadas.");
      return;
    }

    // Actualizar orders estableciendo driverLicensePlate en cadena vacía
    const updatedOrders = orders.map((order) => {
      if (selectedOrders.includes(order.id)) {
        return { ...order, driverLicensePlate: "" };
      }
      return order;
    });

    setOrders(updatedOrders);
    // Limpiar selección
    setSelectedOrders([]);
    setSelectedRoutes([]);
  };

  const handleSelectModeChange = (mode) => {
    setSelectMode(mode);
    if (mode === "lasso" || mode === "polygon") {
      // Activar DrawingManager
      if (drawingManager && mapRef.current) {
        drawingManager.setOptions({
          drawingMode:
            mode === "lasso"
              ? window.google.maps.drawing.OverlayType.POLYGON
              : null,
        });
      }
    } else {
      // Desactivar DrawingManager
      if (drawingManager) {
        drawingManager.setDrawingMode(null);
      }
    }
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  const onLoadDrawingManager = (drawingManagerInstance) => {
    setDrawingManager(drawingManagerInstance);

    window.google.maps.event.addListener(
      drawingManagerInstance,
      "overlaycomplete",
      (event) => {
        if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
          const polygon = event.overlay;
          const selectedIds = [];

          orders.forEach((order) => {
            const position = new window.google.maps.LatLng(
              parseFloat(order.toAddressLocation?._lat),
              parseFloat(order.toAddressLocation?._long)
            );

            if (
              window.google.maps.geometry.poly.containsLocation(
                position,
                polygon
              )
            ) {
              selectedIds.push(order.id);
            }
          });

          setSelectedOrders((prevSelected) => [
            ...prevSelected,
            ...selectedIds,
          ]);
          polygon.setMap(null); // Elimina el polígono del mapa
          setSelectMode(""); // Resetea el modo de selección
        }
      }
    );
  };

  // Filtrar las órdenes y rutas según las rutas seleccionadas en modo "Solo Filtrar"
  const filteredRoutes = isFilterMode
    ? selectedRoutes.length > 0
      ? selectedRoutes
      : routes
    : routes;

  const filterOrders = (orders) => {
    let filteredOrders = orders;

    // Filtrar por búsqueda
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filteredOrders = filteredOrders.filter((order) => {
        const titleId = (order.id || "").toLowerCase();
        const address = (order.address || "").toLowerCase();
        return (
          titleId.includes(lowerCaseQuery) || address.includes(lowerCaseQuery)
        );
      });
    }

    if (selectedStatuses.length > 0) {
      filteredOrders = filteredOrders.filter((order) => {
        const orderStatus = (order.falabella_status || "Sin estado")
          .trim()
          .toLowerCase();
        return selectedStatuses.includes(orderStatus);
      });
    }

    return filteredOrders;
  };

  const handleOpenFilterDialog = () => {
    setOpenFilterDialog(true);
  };

  const handleCancelFilter = () => {
    setOpenFilterDialog(false);
  };

  const handleApplyFilter = () => {
    // Ya usamos filterOrders en el renderizado, no hace falta nada más aquí.
    setOpenFilterDialog(false);
  };

  const handleOpenOptimizeDialog = () => {
    setOpenOptimizeDialog(true);
  };

  const handleCancelOptimize = () => {
    setOpenOptimizeDialog(false);
  };

  // UTILIDADES PARA KMEANS Y OPTIMIZACIÓN
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function haversineDistance(lat1, lng1, lat2, lng2) {
    const earthRadius = 6371; // Radio de la Tierra en km
    const dLat = deg2rad(lat2 - lat1);
    const dLng = deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadius * c;
  }

  function initializeCenters(data, k) {
    const indices = [];
    while (indices.length < k) {
      const idx = Math.floor(Math.random() * data.length);
      if (!indices.includes(idx)) indices.push(idx);
    }
    return indices.map((i) => ({ lat: data[i].lat, lng: data[i].lng }));
  }

  function assignToCenters(data, centers) {
    const assignments = data.map((point) => {
      let bestCenter = 0;
      let bestDist = Infinity;
      centers.forEach((c, i) => {
        const dist = haversineDistance(point.lat, point.lng, c.lat, c.lng);
        if (dist < bestDist) {
          bestDist = dist;
          bestCenter = i;
        }
      });
      return bestCenter;
    });
    return assignments;
  }

  function recalculateCenters(data, assignments, k) {
    const sums = Array.from({ length: k }, () => ({
      lat: 0,
      lng: 0,
      count: 0,
    }));
    assignments.forEach((centerIndex, i) => {
      sums[centerIndex].lat += data[i].lat;
      sums[centerIndex].lng += data[i].lng;
      sums[centerIndex].count += 1;
    });

    return sums.map((s) =>
      s.count > 0
        ? { lat: s.lat / s.count, lng: s.lng / s.count }
        : { lat: 0, lng: 0 }
    );
  }

  function kmeans(data, k, maxIterations = 100) {
    let centers = initializeCenters(data, k);
    let assignments = [];

    for (let iter = 0; iter < maxIterations; iter++) {
      const newAssignments = assignToCenters(data, centers);
      if (JSON.stringify(newAssignments) === JSON.stringify(assignments)) {
        break;
      }
      assignments = newAssignments;
      centers = recalculateCenters(data, assignments, k);
    }

    return { assignments, centers };
  }

  const handleApplyOptimize = async () => {
    try {
      const ordersToOptimize =
        optimizeOptions.selector === "Todos"
          ? orders
          : orders.filter((order) => selectedOrders.includes(order.id));

      const dataToSend = ordersToOptimize
        .filter(
          (o) =>
            o.toAddressLocation &&
            typeof o.toAddressLocation._lat === "number" &&
            typeof o.toAddressLocation._long === "number"
        )
        .map((order) => ({
          id: order.id,
          lat: parseFloat(order.toAddressLocation._lat),
          lng: parseFloat(order.toAddressLocation._long),
        }));

      const totalOrders = dataToSend.length;

      let numberOfRoutes;
      if (optimizeOptions.createRequireAgrupations) {
        if (optimizeOptions.createRequireVehicles) {
          numberOfRoutes = optimizeOptions.vehicleCount;
        } else {
          numberOfRoutes = Math.ceil(
            totalOrders / optimizeOptions.agrupationCount
          );
        }
      } else {
        if (optimizeOptions.createRequireVehicles) {
          numberOfRoutes = optimizeOptions.vehicleCount;
        } else {
          numberOfRoutes = optimizeOptions.vehicleCount; // fallback
        }
      }

      if (dataToSend.length === 0) {
        setOpenOptimizeDialog(false);
        return;
      }

      // Ejecutar K-Means
      const { assignments } = kmeans(dataToSend, numberOfRoutes, 100);

      const clusters = Array.from({ length: numberOfRoutes }, () => []);

      assignments.forEach((clusterIndex, i) => {
        clusters[clusterIndex].push(dataToSend[i].id);
      });

      const updatedOrders = orders.map((order) => {
        const foundCluster = clusters.findIndex((cluster) =>
          cluster.includes(order.id)
        );
        if (foundCluster >= 0) {
          return { ...order, driverLicensePlate: `Ruta_${foundCluster + 1}` };
        }
        return order;
      });

      setOrders(updatedOrders);
      const newRoutes = Array.from(
        new Set(
          updatedOrders.map((order) => order.driverLicensePlate || "Sin Ruta")
        )
      );
      setRoutes(newRoutes);
      setOpenOptimizeDialog(false);
      alert(
        "Órdenes optimizadas y reasignadas a las rutas creadas localmente."
      );
    } catch (error) {
      console.error("Error al optimizar:", error);
      alert("Ocurrió un error al optimizar las rutas.");
    }
  };

  const handleOpenSaveDialog = () => {
    setOpenSaveDialog(true);
  };

  const handleCancelSave = () => {
    setOpenSaveDialog(false);
  };

  const handleSaveDocument = async (companyID) => {
    try {
      const documentToSave = {
        name: documentData.name ?? "",
        date: documentData.date ?? "",
        propietary: companyID,
        routes: {},
        colors: routeColors, // guardamos los colores de cada ruta
      };

      routes.forEach((route) => {
        const minimalOrders = routeInfo.ordersByRoute[route].map((order) => ({
          id: order.id !== undefined ? order.id : null,
          toAddressLocation:
            order.toAddressLocation !== undefined
              ? order.toAddressLocation
              : null,
          orderNumber: order.orderNumber !== undefined ? order.orderNumber : 1,
          falabella_status: order.falabella_status?order.falabella_status : order.intStatus ?order.intStatus  : "",
          address: order.address ? order.address :  order.toAddress ?  order.toAddress  :"ERROR",
        }));

        if (documentData.autoAssignDrivers) {
          documentToSave.routes[route] = {
            driverId: documentData.routeDrivers[route] || "",
            orders: minimalOrders,
          };
        } else {
          documentToSave.routes[route] = {
            driverId: "",
            orders: minimalOrders,
          };
        }
      });
      console.log(documentToSave)

      const docRef = await addDoc(
        collection(db, "xpress_deliveryPlans"),
        documentToSave
      );
      await updateDoc(docRef, { docId: docRef.id });

      setOpenSaveDialog(false);
      alert("Documento guardado exitosamente.");
    } catch (error) {
      console.error("Error al guardar el documento:", error);
      alert("Ocurrió un error al guardar el documento.");
    }
  };

  const exportarHojaRutasActualizaciones = async (routeInfo) => {
    const currentURL = window.location.pathname;
    const prefix = currentURL === "/dashboard/map-plans" ? "" : "F";
    const routeOrders = Object.entries(routeInfo.ordersByRoute).flatMap(
      ([route, orders]) =>
        orders.map((order) => ({
          driverLicensePlate: order.driverLicensePlate || "SIN-RUTA",
          orderNumber: order.orderNumber !== undefined ? order.orderNumber : 1,
          id: order.id || "",
        }))
    );

    const matrizReporte = [
      ["driverLicensePlate", "orderNumber", "PREFIX", "BICCIID"],
      ...routeOrders.map((dato) => [
        dato.driverLicensePlate,
        dato.orderNumber,
        prefix,
        dato.id,
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "Plan");

    XLSX.writeFile(
      libro,
      `Reporte de rutas ${moment(Date.now()).format(
        "YYYY-MM-DD HH_mm_ss"
      )}.xlsx`
    );
  };
  

  const handleOpenPlansDialog = async (companyID) => {
    setOpenPlansDialog(true);
  
    // Crear la consulta con la condición propietary = companyID
    const plansQuery = query(
      collection(db, "xpress_deliveryPlans"),
      where("propietary", "==", companyID)
    );
  
    const querySnapshot = await getDocs(plansQuery);
    const loadedPlans = [];
    querySnapshot.forEach((doc) => {
      loadedPlans.push({ id: doc.id, ...doc.data() });
    });
  
    console.log(loadedPlans);
    setPlans(loadedPlans);
    setCurrentPage(0);
  };
  

  const handleClosePlansDialog = () => {
    setOpenPlansDialog(false);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < plans.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPlans = plans.slice(startIndex, endIndex);

  const loadPlan = (plan) => {
    const allOrders = [];
    const planRouteNames = Object.keys(plan.routes);

    Object.entries(plan.routes).forEach(([routeName, routeData]) => {
      const ordersConRuta = routeData.orders.map((o) => ({
        ...o,
        driverLicensePlate: routeName,
      }));
      allOrders.push(...ordersConRuta);
    });

    setOrders(allOrders);
    setRoutes(planRouteNames);

    // Restaurar los colores si existen en el plan
    if (plan.colors) {
      setRouteColors(plan.colors);
    }

    setOpenPlansDialog(false);
  };

  const handleSelectPlan = (plan) => {
    loadPlan(plan);
  };

  const handleCombineWithRoutes = () => {
    if (!orders || orders.length === 0) return;

    const routeCentroids = {};
    Object.entries(routeInfo.ordersByRoute).forEach(
      ([routeName, routeOrders]) => {
        let sumLat = 0;
        let sumLng = 0;
        let count = 0;
        routeOrders.forEach((o) => {
          if (
            o.toAddressLocation &&
            typeof o.toAddressLocation._lat === "number" &&
            typeof o.toAddressLocation._long === "number"
          ) {
            sumLat += o.toAddressLocation._lat;
            sumLng += o.toAddressLocation._long;
            count++;
          }
        });
        if (count > 0) {
          routeCentroids[routeName] = {
            lat: sumLat / count,
            lng: sumLng / count,
          };
        }
      }
    );

    const earthRadius = 6371;
    const deg2rad = (deg) => deg * (Math.PI / 180);
    const haversineDistance = (lat1, lng1, lat2, lng2) => {
      const dLat = deg2rad(lat2 - lat1);
      const dLng = deg2rad(lng2 - lng1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return earthRadius * c;
    };

    const updatedOrders = [...orders];
    updatedOrders.forEach((o) => {
      if (selectedOrders.includes(o.id)) {
        if (
          o.toAddressLocation &&
          typeof o.toAddressLocation._lat === "number" &&
          typeof o.toAddressLocation._long === "number"
        ) {
          let bestRoute = null;
          let bestDist = Infinity;
          for (const [rName, centroid] of Object.entries(routeCentroids)) {
            const dist = haversineDistance(
              o.toAddressLocation._lat,
              o.toAddressLocation._long,
              centroid.lat,
              centroid.lng
            );
            if (dist < bestDist) {
              bestDist = dist;
              bestRoute = rName;
            }
          }

          if (bestRoute) {
            o.driverLicensePlate = bestRoute;
          }
        }
      }
    });

    setOrders(updatedOrders);
    setSelectedOrders([]);
    setSelectedRoutes([]);
    setTargetRoute("");
  };

  const clearSelection = () => {
    setSelectedOrders([]);
    setSelectedRoutes([]);
    setTargetRoute("");
  };

  // Manejo del hover para resaltar pines
  const handleOrderMouseEnter = (orderId) => {
    setHoveredOrderId(orderId);
  };

  const handleOrderMouseLeave = () => {
    setHoveredOrderId(null);
  };

  // Manejo del diálogo de edición
  const handleOpenEditDialog = (order) => {
    setEditOrder({ ...order });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditOrder(null);
  };

  const handleSaveEditedOrder = async () => {
    if (!editOrder) return;
    console.log("handleSaveEditedOrder: ", editOrder);
    // Aquí puedes implementar la lógica para actualizar la orden.
    // Por ahora, actualizaremos el estado local.

    let newGeo = new GeoPoint(
      parseFloat(newLocation.lat),
      parseFloat(newLocation.lng)
    );

    const orderRef1 = doc(collectionRef, `F${editOrder.id}`);

    try {
      await updateDoc(orderRef1, {
        toAddressLocation: newGeo,
      });
    } catch (error) {
      console.log(error);
    }

    const updatedOrders = orders.map((o) =>
      o.id === editOrder.id ? { ...o, ...editOrder } : o
    );
    setOrders(updatedOrders);
    handleCloseEditDialog();
  };

  return (
    <Box display="flex" height="100%" position="relative">
      {/* Panel lateral con lista de rutas */}
      <Box
        width={350}
        overflow="auto"
        p={2}
        bgcolor="background.paper"
        borderRight={1}
        borderColor="divider"
      >
        {/* Botones arriba de "Rutas" */}
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" fullWidth>
            <Button onClick={handleAddRoute}>+ Agregar Ruta</Button>
            <Button onClick={handleChangeRoute}>Cambiar a</Button>
            <Button onClick={handleRemoveFromRoute}>Sacar de Ruta</Button>
          </ButtonGroup>
          {/* Selector para "Cambiar a" */}
          <FormControl fullWidth variant="outlined" size="small" margin="dense">
            <InputLabel id="select-target-route-label">
              Ruta de destino
            </InputLabel>
            <Select
              labelId="select-target-route-label"
              value={targetRoute}
              onChange={(e) => setTargetRoute(e.target.value)}
              label="Ruta de destino"
            >
              {routes.map((route) => (
                <MenuItem key={route} value={route}>
                  {route}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Campo de búsqueda */}
          <TextField
            label="Buscar por ID"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>

        {/* Switch al lado de "Rutas" */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            Rutas
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isFilterMode}
                onChange={(e) => setIsFilterMode(e.target.checked)}
                color="primary"
              />
            }
            label={isFilterMode ? "Solo Filtrar" : "Seleccionar"}
          />
        </Box>
        <Divider />

        {/* Lista de rutas */}
        <Box sx={{ overflowY: "auto", maxHeight: "80vh" }}>
          <List>
            {filteredRoutes
              .sort((a, b) => a.localeCompare(b)) // Ordenar rutas alfabéticamente
              .map((route) => {
                const count = routeInfo.packageCounts[route] || 0;
                const labelId = `checkbox-list-label-${route}`;
                const isExpanded = expandedRoutes.includes(route);

                // Filtrar las órdenes según el searchQuery
                const ordersInRoute = filterOrders(
                  routeInfo.ordersByRoute[route] || []
                );

                return (
                  <React.Fragment key={route}>
                    <ListItem
                      button
                      onClick={() => handleRouteExpansion(route)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedRoutes.includes(route)}
                          tabIndex={-1}
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRouteSelection(route);
                          }}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${route} - ${count} bultos`}
                        style={{ color: routeColors[route] }}
                      />
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {ordersInRoute?.map((order, index) => {
                          const positionNumber = order.orderNumber || 0;
                          const titleId = order.id || "Sin ID";
                          const address = order.address || "Sin dirección";
                          const weight = order.weight || "Sin peso";
                          const status =
                            translateStatesfalabbela[order.falabella_status] ||
                            "Sin estado";

                          const isSelected = selectedOrders.includes(order.id);

                          // En modo "Solo Filtrar", no permitir selección de órdenes
                          const listItemProps = isFilterMode
                            ? {}
                            : {
                                button: true,
                                onDoubleClick: () =>
                                  handleOrderDoubleClick(order.id),
                                selected: isSelected,
                              };

                          return (
                            <ListItem
                              key={index}
                              style={{
                                paddingLeft: 10,
                                borderBottom: "solid 1px grey",
                              }}
                              {...listItemProps}
                              // Al pasar el mouse sobre la orden, destacar el pin
                              onMouseEnter={() =>
                                handleOrderMouseEnter(order.id)
                              }
                              onMouseLeave={handleOrderMouseLeave}
                            >
                              {!isFilterMode && (
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={isSelected}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={() =>
                                      handleOrderSelection(order.id)
                                    }
                                  />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                primary={`Posición: ${positionNumber} | ID: ${titleId}`}
                                secondary={
                                  <>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      Dirección: {address}
                                    </Typography>
                                    <br />
                                    Peso: {weight} - Estado: {status}
                                  </>
                                }
                              />
                              {/* Botón Editar */}
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenEditDialog(order);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              })}
          </List>
        </Box>
      </Box>

      {/* Mapa */}
      <Box flexGrow={1}>
        <GoogleMap
          id="mapControl3"
          zoom={zoom}
          center={center}
          mapContainerStyle={{ height: "100%", width: "100%" }}
          onLoad={onLoadMap}
          options={options}
        >
          {selectMode === "lasso" && (
            <DrawingManager
              onLoad={onLoadDrawingManager}
              options={{
                drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                drawingControl: false,
                polygonOptions: {
                  fillColor: "#2196F3",
                  fillOpacity: 0.2,
                  strokeWeight: 2,
                  clickable: false,
                  editable: false,
                  zIndex: 1,
                },
              }}
            />
          )}

          {orders &&
            orders.map((order, index) => {
              if (!order.toAddressLocation) {
                return null;
              }

              const position = {
                lat: parseFloat(order.toAddressLocation?._lat),
                lng: parseFloat(order.toAddressLocation?._long),
              };

              if (
                !isNaN(position.lat) &&
                !isNaN(position.lng) &&
                position.lat !== 0 &&
                position.lng !== 0
              ) {
                const route = order.driverLicensePlate || "Sin Ruta";
                const color = routeColors[route] || "blue";

                // Filtrar por rutas en modo filtrar
                if (
                  selectedRoutes.length > 0 &&
                  !selectedRoutes.includes(route)
                ) {
                  if (isFilterMode) {
                    return null;
                  }
                }

                // Filtrar por búsqueda
                const titleId = (order.id || "").toLowerCase();
                const address = (order.address || "").toLowerCase();
                const lowerCaseQuery = searchQuery.toLowerCase();
                if (
                  searchQuery &&
                  !(
                    titleId.includes(lowerCaseQuery) ||
                    address.includes(lowerCaseQuery)
                  )
                ) {
                  return null;
                }

                // Definir un icono personalizado

                const iconPath =
                  "M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645 c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645 C259.253,49.703,209.57,0,148.5,0z M148.5,79.693c16.964,0,30.765,13.953,30.765,31.104c0,17.151-13.801,31.104-30.765,31.104 c-16.964,0-30.765-13.953-30.765-31.104C117.735,93.646,131.536,79.693,148.5,79.693z";

                const icon = {
                  path: iconPath, //window.google.maps.SymbolPath.CIRCLE,
                  fillColor: color,
                  fillOpacity: 1,
                  strokeColor: "black",
                  strokeWeight: 1,
                  scale: 0.07, // reducir el tamaño
                  anchor: new window.google.maps.Point(
                    148.5 * 0.05,
                    272.689 * 0.05
                  ),
                };

                // const customIcon = {
                //   url: "/static/images/locationPinSvg.svg",
                //   scaledSize: new window.google.maps.Size(35, 35),
                //   anchor: new window.google.maps.Point(17.5, 17.5),
                // };

                // Si la orden está seleccionada o está en hover, resaltamos el marcador
                const isSelected = selectedOrders.includes(order.id);
                const isHovered = hoveredOrderId === order.id;
                if (isSelected || isHovered) {
                  icon.scale = 0.09;
                  icon.strokeColor = isHovered ? "#210963" : "#591e8f";
                  icon.strokeWeight = 2;
                  icon.fillColor = isHovered ? "#210963" : "white";
                }

                const markerProps = isFilterMode
                  ? {}
                  : {
                      onClick: () => handleOrderSelection(order.id),
                      onDblClick: () => handleOrderDoubleClick(order.id),
                    };

                return (
                  <MarkerF
                    key={index}
                    position={position}
                    title={route}
                    icon={icon}
                    {...markerProps}
                  />
                );
              } else {
                console.warn(
                  `Coordenadas inválidas para el pedido ${order.id}:`,
                  position
                );
                return null;
              }
            })}
        </GoogleMap>

        {/* Cuadro flotante en la parte inferior derecha */}
        <Paper
          style={{
            position: "absolute",
            bottom: "20%",
            right: 20,
            width: 200,
            padding: 10,
          }}
          elevation={3}
        >
          <Typography variant="subtitle1" gutterBottom>
            Selección
          </Typography>
          <ButtonGroup
            orientation="vertical"
            color="primary"
            variant="contained"
            fullWidth
          >
            <Button onClick={() => handleSelectModeChange("single")}>
              Seleccionar Uno
            </Button>
            <Button onClick={() => handleSelectModeChange("lasso")}>
              Seleccionar con Lazo
            </Button>
            <Button onClick={() => handleSelectModeChange("polygon")}>
              Seleccionar con Polígono
            </Button>
          </ButtonGroup>
          {selectedOrders.length > 0 && (
            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleChangeRoute}
              >
                Cambiar a
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleRemoveFromRoute}
                style={{ marginTop: 10 }}
              >
                Sacar de Ruta
              </Button>
              <Button
                variant="outlined"
                onClick={clearSelection}
                style={{ marginTop: 10 }}
              >
                Limpiar
              </Button>
            </Box>
          )}
        </Paper>

        {/* Diálogo para agregar ruta */}
        <Dialog open={openAddRouteDialog} onClose={handleAddRouteCancel}>
          <DialogTitle>Agregar Nueva Ruta</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nombre de la Ruta"
              fullWidth
              value={newRouteName}
              onChange={(e) => setNewRouteName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddRouteCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleAddRouteConfirm} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Paper
          style={{
            position: "absolute",
            top: "10%",
            right: 20,
            width: 200,
            padding: 10,
          }}
          elevation={3}
        >
          <Typography variant="subtitle1" gutterBottom>
            Acciones
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isChangeMode}
                onChange={(e) => setIsChangeMode(e.target.checked)}
                color="primary"
              />
            }
            label={isChangeMode ? "Ver mapa estandar" : "Ver mapa despejado"}
          />
          <ButtonGroup
            orientation="vertical"
            color="primary"
            variant="contained"
            fullWidth
          >
            <Button onClick={()=> handleOpenPlansDialog(companyID)}>
              ver planes guardados
            </Button>

            <Dialog
              open={openPlansDialog}
              onClose={handleClosePlansDialog}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>Planes Guardados</DialogTitle>
              <DialogContent>
                {plans.length === 0 ? (
                  <Typography>No hay planes guardados.</Typography>
                ) : (
                  <Grid container spacing={2}>
                    {currentPlans.map((plan) => (
                      <Grid item xs={12} sm={6} md={4} key={plan.id}>
                        <Paper
                          onClick={() => handleSelectPlan(plan)}
                          style={{ cursor: "pointer", padding: "10px" }}
                        >
                          <Typography variant="subtitle1">
                            {plan.name || "Sin nombre"}
                          </Typography>
                          <Typography variant="body2">
                            Fecha: {plan.date || "Sin fecha"}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handlePrevPage} disabled={currentPage === 0}>
                  Anterior
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={endIndex >= plans.length}
                >
                  Siguiente
                </Button>
                <Button onClick={handleClosePlansDialog}>Cerrar</Button>
              </DialogActions>
            </Dialog>

            <Button onClick={handleCombineWithRoutes}>
              Combinar con rutas cercanas
            </Button>

            <Button onClick={handleOpenFilterDialog}>
              Filtrar por estados
            </Button>
            <Dialog open={openFilterDialog} onClose={handleCancelFilter}>
              <DialogTitle>Filtrar por Estados</DialogTitle>
              <DialogContent>
                <FormGroup>
                  {availableStatuses.map((status) => (
                    <FormControlLabel
                      key={status}
                      control={
                        <Checkbox
                          checked={selectedStatuses.includes(status)}
                          onChange={(e) => {
                            const normalizedStatus = status
                              .trim()
                              .toLowerCase();
                            if (e.target.checked) {
                              setSelectedStatuses((prev) => [
                                ...prev,
                                normalizedStatus,
                              ]);
                            } else {
                              setSelectedStatuses((prev) =>
                                prev.filter((s) => s !== normalizedStatus)
                              );
                            }
                          }}
                        />
                      }
                      label={translateStatesfalabbela[status]}
                    />
                  ))}
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelFilter} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleApplyFilter} color="primary">
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>

            <Button onClick={handleOpenOptimizeDialog}>Optimizar</Button>

            <Dialog open={openOptimizeDialog} onClose={handleCancelOptimize}>
              <DialogTitle>Optimizar Rutas</DialogTitle>
              <DialogContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={optimizeOptions.retornable}
                      onChange={(e) =>
                        setOptimizeOptions((prev) => ({
                          ...prev,
                          retornable: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Retornable"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={optimizeOptions.fan}
                      onChange={(e) =>
                        setOptimizeOptions((prev) => ({
                          ...prev,
                          fan: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Fan"
                />
                <TextField
                  label="Origen"
                  fullWidth
                  margin="dense"
                  value={optimizeOptions.origen}
                  onChange={(e) =>
                    setOptimizeOptions((prev) => ({
                      ...prev,
                      origen: e.target.value,
                    }))
                  }
                />
                <TextField
                  label="Destino"
                  fullWidth
                  margin="dense"
                  value={optimizeOptions.destino}
                  onChange={(e) =>
                    setOptimizeOptions((prev) => ({
                      ...prev,
                      destino: e.target.value,
                    }))
                  }
                />
                <FormControl fullWidth margin="dense">
                  <InputLabel id="provider-label">Proveedor</InputLabel>
                  <Select
                    labelId="provider-label"
                    value={optimizeOptions.provider}
                    onChange={(e) =>
                      setOptimizeOptions((prev) => ({
                        ...prev,
                        provider: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="Bicci">Bicci</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="selector-label">
                    Objetos a Optimizar
                  </InputLabel>
                  <Select
                    labelId="selector-label"
                    value={optimizeOptions.selector}
                    onChange={(e) =>
                      setOptimizeOptions((prev) => ({
                        ...prev,
                        selector: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="Todos">Todos los objetos</MenuItem>
                    <MenuItem value="Seleccionados">
                      Solo seleccionados
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={optimizeOptions.createRequireVehicles}
                      onChange={(e) =>
                        setOptimizeOptions((prev) => ({
                          ...prev,
                          createRequireVehicles: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Crear Vehículos"
                />
                {!optimizeOptions.createRequireVehicles && (
                  <TextField
                    label="Cantidad de Vehículos"
                    type="number"
                    inputProps={{ min: 1 }}
                    fullWidth
                    margin="dense"
                    value={optimizeOptions.vehicleCount}
                    onChange={(e) =>
                      setOptimizeOptions((prev) => ({
                        ...prev,
                        vehicleCount: e.target.value,
                      }))
                    }
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={optimizeOptions.createRequireAgrupations}
                      onChange={(e) =>
                        setOptimizeOptions((prev) => ({
                          ...prev,
                          createRequireAgrupations: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Crear Agrupaciones"
                />
                {!optimizeOptions.createRequireAgrupations && (
                  <TextField
                    label="Cantidad de Visitas Agrupadas"
                    type="number"
                    inputProps={{ min: 1 }}
                    fullWidth
                    margin="dense"
                    value={optimizeOptions.agrupationCount}
                    onChange={(e) =>
                      setOptimizeOptions((prev) => ({
                        ...prev,
                        agrupationCount: e.target.value,
                      }))
                    }
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelOptimize} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleApplyOptimize} color="primary">
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>

            <Button onClick={handleOpenSaveDialog}>Guardar documento</Button>

            <Dialog open={openSaveDialog} onClose={handleCancelSave}>
              <DialogTitle>Guardar Documento</DialogTitle>
              <DialogContent>
                <TextField
                  label="Nombre del Documento"
                  fullWidth
                  margin="dense"
                  value={documentData.name}
                  onChange={(e) =>
                    setDocumentData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                <TextField
                  label="Fecha"
                  type="date"
                  fullWidth
                  margin="dense"
                  value={documentData.date}
                  onChange={(e) =>
                    setDocumentData((prev) => ({
                      ...prev,
                      date: e.target.value,
                    }))
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={documentData.autoAssignDrivers}
                      onChange={(e) =>
                        setDocumentData((prev) => ({
                          ...prev,
                          autoAssignDrivers: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Auto-asignar conductores"
                />
                {documentData.autoAssignDrivers && (
                  <Box mt={2}>
                    {routes.map((route) => (
                      <FormControl
                        fullWidth
                        margin="dense"
                        key={route}
                        style={{ marginBottom: 10 }}
                      >
                        <InputLabel id={`driver-select-label-${route}`}>
                          Conductor para {route}
                        </InputLabel>
                        <Select
                          labelId={`driver-select-label-${route}`}
                          value={documentData.routeDrivers[route] || ""}
                          onChange={(e) =>
                            setDocumentData((prev) => ({
                              ...prev,
                              routeDrivers: {
                                ...prev.routeDrivers,
                                [route]: e.target.value,
                              },
                            }))
                          }
                        >
                          {ridersDataByCompany.map((driver) => (
                            <MenuItem key={driver.id} value={driver.id}>
                              {driver.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ))}
                  </Box>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 10 }}
                  onClick={() => exportarHojaRutasActualizaciones(routeInfo)}
                >
                  Descargar Documento
                </Button>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelSave} color="primary">
                  Cancelar
                </Button>
                <Button onClick={() => handleSaveDocument(companyID)} color="primary">
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          </ButtonGroup>
        </Paper>
      </Box>

      {/* Diálogo Editar Orden */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        sx={{ width: 700, height: 700 }}
      >
        <DialogTitle>Editar Orden {editOrder && editOrder.id}</DialogTitle>
        <DialogContent>
          {editOrder && (
            <>
              <TextField
                label="Dirección"
                fullWidth
                margin="dense"
                value={editOrder.address || ""}
                onChange={(e) =>
                  setEditOrder((prev) => ({ ...prev, address: e.target.value }))
                }
              />

              {/* Sección de Ajustar Geo Referencia */}
              <Typography
                fontFamily="Lato, Arial, Helvetica, sans-serif"
                color="textPrimary"
                gutterBottom
                variant="body"
                sx={{ marginTop: 2, marginBottom: 1 }}
              >
                Ajustar Geo referencia de entrega:
              </Typography>

              {/* Mostrar georeferencia actual */}
              {editOrder.toAddressLocation ? (
                <Typography variant="body2" gutterBottom>
                  Geo referencia actual:
                  <div>
                    Lat:{" "}
                    {editOrder.toAddressLocation.latitude
                      ? editOrder.toAddressLocation.latitude.toString()
                      : editOrder.toAddressLocation._lat
                      ? editOrder.toAddressLocation._lat.toString()
                      : ""}
                    , Lng:{" "}
                    {editOrder.toAddressLocation.longitude
                      ? editOrder.toAddressLocation.longitude.toString()
                      : editOrder.toAddressLocation._long
                      ? editOrder.toAddressLocation._long.toString()
                      : ""}
                  </div>
                </Typography>
              ) : (
                <Typography variant="body2" gutterBottom>
                  No hay georeferencia actual.
                </Typography>
              )}

              {newLocation && newLocation.lat ? (
                <Box mt={1}>
                  <Typography variant="body2">
                    Nueva Geo referencia Seleccionada:
                  </Typography>
                  <Typography variant="body2">
                    Lat: {newLocation.lat.toString()}, Lng:{" "}
                    {newLocation.lng.toString()}
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      color: "#fff",
                      marginTop: "10px",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      // Guardar la nueva georeferencia en editOrder
                      setEditOrder((prev) => ({
                        ...prev,
                        toAddressLocation: {
                          latitude: newLocation.lat,
                          longitude: newLocation.lng,
                        },
                      }));
                      // Limpiar newLocation si lo deseas
                      // setNewLocation(null);
                    }}
                  >
                    Guardar Geo Referencia
                  </Button>
                </Box>
              ) : null}

              <Box mt={2}>
                {/* Componente para seleccionar nueva georeferencia */}
                <SelectorGeoRecf
                  markersPending={editOrder.toAddressLocation}
                  setNewLocation={setNewLocation}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEditedOrder} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MapComponentDistributions;
