import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { Avatar, Chip, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { purple, grey, green } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventIcon from "@mui/icons-material/Event";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import RouteIcon from "@mui/icons-material/Route";
import { Button } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Form, Input } from "semantic-ui-react";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import generarReporte from "../../../../utils/Reports/generarReporte";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useEffect } from "react";
import SwipeableSearcherDrawer from "../../../../pages/Dashboard/Guias/SwipeableSearcherDrawer";
import DashIconsResumenDispachsMinimal from "../../DashIconsResumenDispachs/DashIconsResumenDispachsMinimal";
import Grid from "@mui/material/Grid";
import generarArchivoCargaSimpliRoute from "../../../../utils/Reports/generarArchivoCargaSimplirouteApp";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { fDate } from "../../../../utils/objects/FormatTime";
import { useDateRangePicker } from "../../../CustomDateRangePicker";
import CustomDateRangePicker from "../../../CustomDateRangePicker/CustomDateRangePicker";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import useHandleUpdateRoutesAndPositions from "../../../../hooks/useHandleUpdateRoutesAndPositions/useHandleUpdateRoutesAndPositions";
import DashIconsResumenIncidencias from "../../DashIconsResumenDispachs/DashIconsResumenIncidencias";
import useHandleUpdateLostDelivery from "../../../../hooks/useHandleUpdateLostDelivery/useHandleUpdateLostDelivery";
import { CustomDateRangePickerStyle } from "../../../CustomDateRangePicker/CustomDateRangePickerStyle";

export default function ToolDashAppBar(props) {
  const [viewCreateRoutes, setViewCreateRoutes] = React.useState(false);
  const [viewSelectTimes, setViewSelectTimes] = React.useState(false);
  const [viewCreateRoutesGoogle, setViewCreateRoutesGoogle] =
    React.useState(false);
  const [viewSelectorDatesRang, setViewSelectorDatesRang] =
    React.useState(false);
  const [viewFindAnSelectorOrdes, setViewFindAnSelectorOrdes] =
    React.useState(false);

  const [viewViewSelectorOrders, setViewSelectorOrders] = React.useState(false);
  const [viewFinFindOrdes, setViewFindOrdes] = React.useState(false);
  const [viewViewSearchOrder, setViewSearchOrder] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);

  const [listIDs, setListIDs] = React.useState("");

  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);
  const [dataSearch, setDataSearch] = React.useState("");
  const [rows, setRows] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());
  const rangeCalendarPicker = useDateRangePicker(new Date(), null);

  const [file, setFile] = React.useState(null);
  const { data, loading, error, uploadDocument } =
    useHandleUpdateRoutesAndPositions();
  //const [viewFindAnSelectorOrdes, setViewFindAnSelectorOrdes] =React.useState(false);
  const {
    dataUpdateLost,
    loadingUpdateLost,
    errorUpdateLost,
    uploadDocumentUpdateLost,
  } = useHandleUpdateLostDelivery();

  useEffect(() => {
    if (props.dataOrdersForAccion) {
    }
    setDataMergeLocalOrder(props.dataMerge);
  }, [viewCreateRoutes, props, viewFinFindOrdes]);

  useEffect(() => {}, [rangeInputPicker]);

  const handleChangeListToSelect = (e) => {
    setListIDs(e.target.value);
  };

  const setNewDate = () => {
    let createDate = { name: "createDate", value: rangeInputPicker.startDate };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    filterData(dataSearch);
  }, [dataSearch]);

  // useEffect(() => {
  //   handleUpload();
  // }, [file]);

  const filterData = async (dataSearch) => {
    const filterFunction = (obj, searchTerm) => {
      for (let key in obj) {
        const value = obj[key];
        if (typeof value === "string" && value.includes(searchTerm)) {
          return true;
        }
        if (typeof value === "object") {
          if (filterFunction(value, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };

    const filteredData = dataMergeLocalOrder.filter((item) =>
      filterFunction(item, dataSearch)
    );

    const rowsResult = filteredData.map((item) => {
      // Aquí puedes mapear los campos del objeto filtrado a la estructura que necesites
      return createData(
        item["id"],
        item["customer_name"],
        item["customer_phone"],
        item["customer_email"],
        item["driverName"],
        item["upDatedAtText"],
        item["address"],
        item["commune1"],
        item["id"],
        item["driverAvatar"]
      );
    });

    // console.log(rowsResult);
    setRows(rowsResult);
  };

  function createData(
    OrderID,
    Cliente,
    ClienteTelefono,
    ClienteEmail,
    Conductor,
    Fecha,
    Adress,
    Zona,
    Detalles,
    driverAvatar
  ) {
    return {
      OrderID,
      Cliente,
      ClienteTelefono,
      ClienteEmail,
      Conductor,
      Fecha,
      Adress,
      Zona,
      Detalles,
      driverAvatar,
    };
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleSelectBtnSearchInterfaz = async (e) => {
    await handleCloseBtnSearchInterfaz();
    switch (e.target.name) {
      case "viewFinFindOrdes":
        setViewFindOrdes(true);
        break;
      case "viewViewSelectorOrders":
        setViewSelectorOrders(true);
        break;
      case "viewViewSearchOrder":
        setViewSearchOrder(true);
        break;
      default:
        break;
    }
  };

  const handleCloseBtnSearchInterfaz = async () => {
    setViewFindOrdes(false);
    setViewSelectorOrders(false);
    setViewSearchOrder(false);
  };

  const handleMenuReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  const accionArchivoCargaSimpliRoute = (data) => {
    generarArchivoCargaSimpliRoute(data);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      await uploadDocument(selectedFile);
      setViewFindAnSelectorOrdes(true);
      resetState();
    }
  };

  const handleFileChangeUpdateLost = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      await uploadDocumentUpdateLost(selectedFile);
      setViewFindAnSelectorOrdes(true);
      resetState();
    }
  };

  const resetState = () => {
    setFile(null);
    setViewFindAnSelectorOrdes(false);
    document.getElementById("upload-input").value = null; // Resetear el input de archivo
  };

  const sincierre = [1058, 1059, 1060, 1061];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={16}>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "40%",
            },
          }}
        ></Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "60%",
            },
          }}
        ></Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          backgroundColor: "#F8F8F8", //grey[200],
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() =>
                  props.setRealTimeisActive(!props.realTimeisActive)
                }
                sx={{ color: "#4955af", radius: "3px" }}
              >
                {props.realTimeisActive ? (
                  <>
                    <ChangeCircleIcon
                      sx={{ color: green[500], radius: "3px" }}
                    ></ChangeCircleIcon>{" "}
                    <Typography variant="body2">
                      Tiempo real Activado
                    </Typography>
                  </>
                ) : (
                  <>
                    <CachedIcon
                      sx={{ color: "#4955af", radius: "3px" }}
                    ></CachedIcon>

                    <Typography variant="body2">
                      Tiempo real Desactivado
                    </Typography>
                  </>
                )}
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewSelectTimes(!viewSelectTimes)}
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Buscar en rangos de fecha">
                  <CalendarMonthIcon
                    sx={{ color: viewSelectTimes ? green[500] : "#4955af" }}
                  />
                </Tooltip>
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() =>
                  setViewFindAnSelectorOrdes(!viewFindAnSelectorOrdes)
                }
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Buscar envíos">
                  <SearchIcon
                    sx={{
                      color: viewFindAnSelectorOrdes ? green[500] : "#4955af",
                    }}
                  />
                </Tooltip>
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                  setViewCreateRoutes(!viewCreateRoutes);
                  props.setWiewAssignment(true);
                }}
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Crear Rutas">
                  <RouteIcon
                    sx={{ color: viewCreateRoutes ? green[500] : "#4955af" }}
                  />
                </Tooltip>
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{ color: "#4955af" }}
              >
                {" "}
                <Tooltip title="Configurar vistas">
                  <DisplaySettingsIcon sx={{ color: "#4955af" }} />
                </Tooltip>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} sx={{ color: "#4955af" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.CheckedChangeOE}
                        onChange={props.handleChangeOE}
                        aria-label="handleChangeOE switch"
                        sx={{
                          color: props.CheckedChangeOE
                            ? green[500]
                            : purple[500],
                        }}
                      />
                    }
                    label={
                      props.CheckedChangeOE
                        ? "Ocultar incidencias activas"
                        : "Mostrar incidencias activas"
                    }
                    sx={{
                      color: props.CheckedChangeOE ? green[500] : purple[500],
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ color: "#4955af" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.typeRequets.typeRequetsCreate}
                        onChange={props.handleChangetypeRequets}
                        aria-label="typeRequetsCreate switch"
                        name={"typeRequetsCreate"}
                        sx={{
                          color: props.typeRequets.typeRequetsCreate
                            ? green[500]
                            : purple[500],
                        }}
                      />
                    }
                    label={
                      props.typeRequets.typeRequetsCreate
                        ? "Mostrar Por F. Creacion"
                        : "Ocultar Por F. Ultima Act"
                    }
                    sx={{
                      color: props.typeRequets.typeRequetsCreate
                        ? green[500]
                        : purple[500],
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ color: "#4955af" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.typeRequets.typeRequetsUpdate}
                        onChange={props.handleChangetypeRequets}
                        name={"typeRequetsUpdate"}
                        aria-label="typeRequetsUpdate switch"
                        sx={{
                          color: props.typeRequets.typeRequetsUpdate
                            ? green[500]
                            : purple[500],
                        }}
                      />
                    }
                    label={
                      props.typeRequets.typeRequetsUpdate
                        ? "Ocultar Por F. Ultima Act"
                        : "Mostrar Por F. Creacion"
                    }
                    sx={{
                      color: props.typeRequets.typeRequetsUpdate
                        ? green[500]
                        : purple[500],
                    }}
                  />
                </MenuItem>
              </Menu>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuReport}
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Crear Reportes">
                  <SaveAltIcon sx={{ color: "#4955af" }} />
                </Tooltip>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElReport}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElReport)}
                onClose={handleCloseMenuReport}
              >
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <>
                      <button
                        onClick={() => generarReporte(props.dataMerge)}
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Pagos Riders
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteTurno(props.dataMerge)
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Cierre Turno Salida directas
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteDiscrepancias(props.dataMerge)
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte No Piking
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteExcepcionesPendientes(
                            props.dataMerge
                          )
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        {
                          <ErrorOutlineIcon
                            sx={{
                              color: "#dc3545",
                              background: "#fff",
                              borderRadius: "50%",
                            }}
                          ></ErrorOutlineIcon>
                        }{" "}
                        Reporte de Excepciones
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataOrdersForAccions &&
                  props.dataOrdersForAccions.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteEstados(
                            props.dataOrdersForAccions
                          )
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Estados
                      </button>
                    </>
                  ) : (
                    <div>
                      Seleccione Órdenes para, exportar un reporte de Estados
                    </div>
                  )}
                </MenuItem>
                {/* <MenuItem
                onClick={handleCloseMenuReport}
                sx={{ color: "#4955af" }}
              >
                {props.dataMerge &&
                props.dataMerge.length > 0 &&
                props.dataOrdersForAccions &&
                props.dataOrdersForAccions.length > 0 ? (
                  <>
                    {props.dataOrdersForAccions ? (
                      <button
                        onClick={() =>
                          props.generarReporteEvidencias(
                            props.dataOrdersForAccions
                          )
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte de Evidencias
                      </button>
                    ) : (
                      <div>No hay ordenes Seleccionadas</div>
                    )}
                    <button
                      onClick={() =>
                        props.generarReporteEvidencias(
                          props.dataOrdersForAccions
                        )
                      }
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Reporte de Evidencias
                    </button>
                  </>
                ) : (
                  <div>
                    Seleccione Órdenes para, exportar un reporte de Evidencias
                  </div>
                )}
              </MenuItem> */}
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataMerge &&
                  props.dataMerge.length > 0 &&
                  props.findHistorials ? (
                    <button
                      onClick={() => props.findHistorials()}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Ver Historiales de Órdenes
                    </button>
                  ) : null}
                </MenuItem>

                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#4955af" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <button
                      onClick={() => props.setWiewPrePdf(!props.wiewPrePdf)}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {props.wiewPrePdf === true
                        ? "Cerrar vista previa de reporte"
                        : "Abrir reporte Cierre Diario"}
                    </button>
                  ) : null}
                </MenuItem>
              </Menu>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() =>
                  accionArchivoCargaSimpliRoute(dataMergeLocalOrder)
                }
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Doc Data SimpliRoute">
                  <SystemUpdateAltIcon
                    sx={{
                      color: viewFindAnSelectorOrdes ? green[500] : "#4955af",
                    }}
                  />
                </Tooltip>
              </IconButton>{" "}
            </div>
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="upload-input"
              />
              <label htmlFor="upload-input">
                <IconButton
                  size="large"
                  aria-label="upload document"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{ color: "#4955af" }}
                  component="span"
                >
                  <Tooltip title="Re-Ordenar Rutas, Con Data Simpli Route">
                    <CloudSyncIcon
                      sx={{
                        color: viewFindAnSelectorOrdes ? green[500] : "#4955af",
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </label>
              {loading && <div>Cargando...</div>}
              {data && !loading && <div>{data.response}</div>}
              {error && <div>Error al subir el documento: {error.message}</div>}
            </div>
            {/* 
            <div>
              <input
                type="file"
                onChange={handleFileChangeUpdateLost}
                style={{ display: "none" }}
                id="upload-input2"
              />
              <label htmlFor="upload-input2">
                <IconButton
                  size="large"
                  aria-label="upload document"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{ color: "#4955af" }}
                  component="span"
                >
                  <Tooltip title="Data de perdidas">
                    <CloudSyncIcon
                      sx={{
                        color: viewFindAnSelectorOrdes ? green[500] : "#4955af",
                      }}
                    />{" "}
                  </Tooltip>{" "}
                  Lost Delivery
                </IconButton>
              </label>
              {loadingUpdateLost && <div>Cargando...</div>}
              {dataUpdateLost && !loadingUpdateLost && (
                <div>{dataUpdateLost.response}</div>
              )}
              {errorUpdateLost && (
                <div>
                  Error al subir el documento: {errorUpdateLost.message}
                </div>
              )}
            </div> */}

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={
                  () => props.setWiewAssignment(!props.wiewAssignmentIndIcon)
                  //accionArchivoCargaSimpliRoute(dataMergeLocalOrder)
                }
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Ver Resumen de operaciones">
                  <AssignmentIndIcon
                    sx={{
                      color: props.wiewAssignmentIndIcon
                        ? green[500]
                        : "#4955af",
                    }}
                  />
                </Tooltip>
              </IconButton>{" "}
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={
                  () => props.setOpemPopUpAsignament(true)
                  //accionArchivoCargaSimpliRoute(dataMergeLocalOrder)
                }
                sx={{ color: "#4955af" }}
              >
                <Tooltip title="Asignar conductor">
                  <DirectionsBikeIcon
                  // sx={{
                  //   color: props.wiewAssignmentIndIcon
                  //     ? green[500]
                  //     : "#4955af",
                  // }}
                  />
                </Tooltip>
              </IconButton>{" "}
            </div>
          </Box>
        </Grid>
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "30%",
              },
            }}
          >
            <div>
              {props.dataMerge.filter((orden) => !orden.driverName).length >
                0 ||
              props.dataMerge.filter((orden) =>
                sincierre.includes(orden.falabella_status)
              ).length > 0 ? (
                <div>
                  {props.dataMerge.filter((orden) => !orden.driverName).length >
                  0 ? (
                    <>
                      <Chip
                        sx={{
                          backgroundColor: "#fffb3c",
                          color: "#182ed7",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          textAlign: "center",
                          margin: "10px 0",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          fontSize: "1em",
                        }}
                        label={`Pendientes Asignar : ${
                          props.dataMerge.filter((orden) => !orden.driverName)
                            .length
                        }`}
                      />
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div>
              {props.dataMerge.filter((orden) => !orden.isPickedUp).length >
              0 ? (
                <div>
                  {props.dataMerge.filter((orden) => !orden.isPickedUp).length >
                  0 ? (
                    <>
                      <Chip
                        sx={{
                          backgroundColor: "#e21111",
                          color: "#F8F8F8",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          textAlign: "center",
                          margin: "10px 0",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          fontSize: "1em",
                        }}
                        label={`Sin Escanear en Bodega : ${
                          props.dataMerge.filter((orden) => !orden.isPickedUp)
                            .length
                        }`}
                      />
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div>
              {props.dataOrdersForAccions.length > 0 ? (
                <div>
                  <>
                    <Chip
                      sx={{
                        backgroundColor: "green",
                        color: "#F8F8F8",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "10px 0",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        fontSize: "1em",
                      }}
                      label={` SELECCIONADOS PARA RUTA: ${props.dataOrdersForAccions.length}`}
                    />
                  </>
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "70%",
              },
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "right",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-end",
                },
                textAlign: "right",
              }}
            >
              <div className="">
                <DashIconsResumenDispachsMinimal
                  items={props.listItems}
                  cant={props.cantListItems}
                ></DashIconsResumenDispachsMinimal>
                {props && props.datailInsures ? (
                  <DashIconsResumenIncidencias
                    datailInsures={props.datailInsures}
                  ></DashIconsResumenIncidencias>
                ) : null}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
      </Grid>
      {viewSelectTimes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            {/* <Toolbar
              sx={{
                backgroundColor: grey[200],
              }}
            >
              <Grid container spacing={2} columns={16}>
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "30%", 
                      sm: "30%", 
                      md: "30%", 
                      lg: "30%", 
                    },
                  }}
                >
                  <Button
                    className="btn-tool-small "
                    onClick={() =>
                      setViewSelectorDatesRang(!viewSelectorDatesRang)
                    }
                  >
                    {viewSelectorDatesRang === false
                      ? "Buscar por Rango de fecha"
                      : "Buscar por día"}
                  </Button>
                </Grid>{" "}
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "70%", 
                      sm: "70%", 
                      md: "70%", 
                      lg: "70%", 
                    },
                  }}
                >
                  {viewSelectorDatesRang === false ? (
                    <div className="typeMiniformsBarsAccionsItemsMinimal">
                      <div>Buscar por día</div>

                      <Form.Field
                        id="staticDateBillingFilter"
                        max={Moment(Date.now()).format("YYYY-MM-DD")}
                        control={Input}
                        iconPosition="left"
                        type="date"
                        name="staticDateBillingFilter"
                        onChange={(e) => props.onChageRange(e)}
                        value={props.staticDateBillingFilter}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="typeMiniformsBarsAccionsItemsMinimal">
                        <div> Fecha Inicio </div>
                        <Form.Field
                          id="createDate"
                          control={Input}
                          max={Moment(Date.now()).format("YYYY-MM-DD")}
                          format={"YYYY-MM-DD"}
                          type="date"
                          name="rangoInicio"
                          onChange={(e) => props.onChageRange(e)}
                          value={
                            props.initDate
                              ? props.initDate
                              : props.initDateBillingFilter
                          }
                        />
                      </div>

                      <div className="typeMiniformsBarsAccionsItemsMinimal">
                        <div> Fecha Fin </div>
                        <Form.Field
                          id="FinalDate"
                          max={Moment(Date.now()).format("YYYY-MM-DD")}
                          control={Input}
                          format={"YYYY-MM-DD"}
                          type="date"
                          name="rangoFinal"
                          onChange={(e) => props.onChageRange(e)}
                          value={
                            props.endDate
                              ? props.endDate
                              : props.endtDateBillingFilter
                          }
                        />
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Toolbar> */}
            <Toolbar
              sx={{
                backgroundColor: grey[200],
              }}
            >
              <Grid container columns={16}>
                <Button
                  variant="contained"
                  onClick={rangeInputPicker.onOpen}
                  sx={CustomDateRangePickerStyle}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <div sx={{ typography: "body2" }}>
                      <strong>Desde:</strong>{" "}
                      {fDate(rangeInputPicker.startDate)}
                      <EventIcon color="primary" /> {/* Ícono de calendario */}
                    </div>
                    <div sx={{ typography: "body2" }}>
                      <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
                      <EventIcon color="primary" /> {/* Ícono de calendario */}
                    </div>
                  </Stack>
                </Button>

                <CustomDateRangePicker
                  variant="calendar" // input
                  open={rangeInputPicker.open}
                  startDate={rangeInputPicker.startDate}
                  endDate={rangeInputPicker.endDate}
                  onChangeStartDate={rangeInputPicker.onChangeStartDate}
                  onChangeEndDate={rangeInputPicker.onChangeEndDate}
                  onClose={rangeInputPicker.onClose}
                  error={rangeInputPicker.error}
                  setNewDate={setNewDate}
                />
              </Grid>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                backgroundColor: grey[200],
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  className="btn-tool-small "
                  name="viewFinFindOrdes"
                  onClick={(e) => handleSelectBtnSearchInterfaz(e)} //</ButtonGroup>setViewFindOrdes(!viewFinFindOrdes)}
                >
                  Encontrar en el rango actual
                </Button>

                <Button
                  className="btn-tool-small "
                  name="viewViewSelectorOrders"
                  onClick={(e) => handleSelectBtnSearchInterfaz(e)} //setViewSelectorOrders(!viewViewSelectorOrders)}
                >
                  Seleccionar una lista de Ids
                </Button>

                <SwipeableSearcherDrawer
                  companyId={props.companyId}
                  iconColor={"#FFF"}
                  styleClassName={"btn-tool-small"}
                ></SwipeableSearcherDrawer>
              </ButtonGroup>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewFinFindOrdes === true && viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                backgroundColor: grey[50],
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <div>
                <Form.Field
                  sx={{
                    backgroundColor: "transparent",
                    border: "0.1",
                    borderRadius: "15px",
                    color: "#000",
                    fontSize: "inherit",
                    height: "auto",
                    width: "100px",
                    outline: "none",
                    paddingInlineEnd: "44px",
                    position: "relative",
                    textAlign: "left",
                    appearance: "menulist-button",
                    backgroundImage: "none",
                    maxWidth: "250px",
                    display: "inline-block",
                    margin: "3px",
                  }}
                  control={Input}
                  placeholder="Buscar..."
                  iconPosition="left"
                  type="text"
                  onChange={(e) => setDataSearch(e.target.value)}
                  value={dataSearch}
                />
              </div>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}
      {viewViewSelectorOrders === true && viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                backgroundColor: grey[50],
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <Grid container spacing={3} columns={16}>
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "30%",
                    },
                  }}
                >
                  <Button
                    className="btn-tool-small "
                    onClick={() => props.setListIdstoSelct(listIDs)}
                  >
                    {" "}
                    Buscar y Seleccionar Ids
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "70%",
                      sm: "70%",
                      md: "70%",
                      lg: "70%",
                    },
                  }}
                >
                  <div className="typeMiniformsBarsAccionsItemsMinimal">
                    <div className="m-1">
                      <div> Ingresar Ids </div>
                      <input
                        className="extentButton p-1"
                        type="textArea"
                        cols="40"
                        rows="5"
                        name="Text1"
                        id="Text1"
                        value={listIDs}
                        onChange={handleChangeListToSelect}
                      />
                    </div>
                  </div>

                  <div className="typeMiniformsBarsAccionsItemsMinimal">
                    {props.dataOrdersForAccions &&
                    props.dataOrdersForAccions.length > 0 ? (
                      <div>
                        Seleccionados :{" "}
                        <h2>{props.dataOrdersForAccions.length}</h2>
                      </div>
                    ) : (
                      <div>No hay Seleccionados</div>
                    )}
                  </div>

                  {props.listadeItemsNoMach &&
                  props.listadeItemsNoMach.length > 0 ? (
                    <div>
                      Error, en ID : {props.listadeItemsNoMach.length}, en
                      intento de selección el id debe coincidir exactamente con
                      la busqueda, separado por comas ejemplo F951623, F745896
                      <button
                        onClick={(e) =>
                          props.handleCopySelectRiderCurrentListErrors(
                            e,
                            props.listadeItemsNoMach
                          )
                        }
                        className="btn-add-guide-small m-2"
                      >
                        {" "}
                        Copy{" "}
                      </button>
                    </div>
                  ) : null}
                </Grid>
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "30%",
                    },
                  }}
                ></Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}
      {viewViewSearchOrder === true && viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                backgroundColor: grey[200],
                display: "flex",
              }}
            >
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <div className="typeMiniformsBarsAccionsItemsMinimal"></div>
                <button
                  onClick={() => console.log("Buscar")}
                  disabled={props.Buttondisable}
                  className={
                    props.adressOrigin && props.adressDestiny
                      ? "btn btn-ebiex extentButton"
                      : "extentButton Buttondisable"
                  }
                >
                  {" "}
                  Buscar
                </button>
              </ButtonGroup>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}
      {viewCreateRoutes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                backgroundColor: grey[200],
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  className="btn-tool-small-onlyColor"
                  onClick={() =>
                    setViewCreateRoutesGoogle(!viewCreateRoutesGoogle)
                  }
                >
                  Ruta Google Maps
                </Button>
                <Button
                  className="btn-tool-small-onlyColor"
                  onClick={() => props.isShowPopUp("ExcelFile")}
                >
                  Ruta Circuit
                </Button>
                <Button
                  className="btn-tool-small-onlyColor"
                  onClick={() =>
                    accionArchivoCargaSimpliRoute(props.dataOrdersForAccions)
                  }
                >
                  Generar data para Simpli
                </Button>
                <Button
                  className="btn-tool-small-onlyColor"
                  onClick={() => props.isShowPopUp("RutaBicciexpress")}
                >
                  Ruta Bicci
                </Button>
              </ButtonGroup>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}
      {viewCreateRoutesGoogle === true && viewCreateRoutes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Grid container spacing={3} columns={16}>
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "30%",
                    lg: "30%",
                  },
                }}
              >
                <div className="typeMiniformsBarsAccionsItemsMinimal">
                  <span>Google Maps API: Origen.</span>
                  <div className="m-2">
                    <Search
                      setSelected={props.setSelected}
                      setOrigenGeoCode={props.setOrigenGeoCode}
                      setAdressOrigin={props.setAdressOrigin}
                      adressOrigin={props.adressOrigin}
                      id="imputSearchFromOrigen"
                    />
                  </div>
                </div>
              </Grid>{" "}
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "30%",
                    lg: "30%",
                  },
                }}
              >
                <div className="typeMiniformsBarsAccionsItemsMinimal">
                  <span>Google Maps API: Destino.</span>
                  <div className="m-2">
                    <SearchDestiny
                      setSelecteDestiny={props.setSelecteDestiny}
                      setDestinyGeoCode={props.setDestinyGeoCode}
                      setAdressDestiny={props.setAdressDestiny}
                      adressDestiny={props.adressDestiny}
                      id="imputSearchFromDestiny"
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "30%",
                    lg: "30%",
                  },
                }}
              >
                <button
                  onClick={() => props.isShowPopUp("GoogleMaps")}
                  disabled={props.Buttondisable}
                  className={
                    props.adressOrigin && props.adressDestiny
                      ? "btn-tool-small"
                      : "extentButton Buttondisable m-1"
                  }
                >
                  {" "}
                  {props.adressOrigin && props.adressDestiny
                    ? "Crear Ruta Google Maps"
                    : "Ingresa dirección origen y destino para continuar"}
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2} columns={16}>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
      </Grid>
      <div>
        <Box sx={{ width: "100%" }}>
          {dataSearch.length > 0 &&
          viewFinFindOrdes === true &&
          viewFindAnSelectorOrdes === true ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell align="right">Conductor</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Zona</TableCell>
                    <TableCell align="right">Ver&nbsp;Detalles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div>
                          <div>
                            <strong>{row.OrderID}</strong>
                          </div>
                          <div>{row.Cliente}</div>
                          <div>9{row.ClienteTelefono}</div>
                          <div>{row.ClienteEmail}</div>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div>
                          <div>
                            <Avatar
                              src={row.driverAvatar}
                              sx={{ bgcolor: "#f2f2f2" }}
                              aria-label="recipe"
                            />
                          </div>
                          <div>
                            {row.Conductor ? row.Conductor : "No Asignado"}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.Fecha}</TableCell>
                      <TableCell align="right">{row.Zona}</TableCell>
                      <TableCell align="right">
                        <NavLink
                          to={{
                            pathname: `/dashboard/dispatch/details/F${row.OrderID}`,
                          }}
                          color="primary"
                          variant="contained"
                        >
                          {" "}
                          ver{" "}
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>
      </div>
    </Box>
  );

  function Search({ setSelected, setAdressOrigin, setOrigenGeoCode }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      // console.log(address)
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressOrigin(address);
        //console.log(address)

        const { lat, lng } = await getLatLng(results[0]);

        setOrigenGeoCode({ lat: lat, lng: lng });
        //console.log(lat, lng)

        props.setButtondisable(false);
      } catch (error) {
        //console.log("😱 Error: ", error);
        //console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Buscar Origen..."
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  function SearchDestiny({
    setSelecteDestiny,
    setAdressDestiny,
    setDestinyGeoCode,
    adressDestiny,
  }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      // console.log(address)
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressDestiny(address);
        //console.log(address)

        const { lat, lng } = await getLatLng(results[0]);

        setDestinyGeoCode({ lat: lat, lng: lng });
        //console.log(lat, lng)

        // setButtondisable(false)
      } catch (error) {
        //console.log("😱 Error: ", error);
        //console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={"Buscar destino.."}
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
}
