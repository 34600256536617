export const translateStatesBicci = {
  0: "Creado",
  1: "Confirmado",
  2: "Confirmado",
  3: "Asignado",
  4: "En ruta Punto A",
  5: "Conductor Confirma el producto",
  6: "En ruta en Destino al cliente",
  7: "Visitado",
  8: "entregado",
  9: "entregado",
  10: "entregado",
  "-1": "Devolución",
  "-2": "Devolución",
  "-3": "Devolución",
  Devolucion: "Devolución",
  ClaimManagement: "Gestión de Siniestro",
  ClaimManagementIsure: "Gestión de acídente con perdidas Trancito / Operativo",
  LossManagement: "Gestión de Perdida / Extraviado",
  returne: "Folio Retornado al HUB",
};

export const translateStatesBicciFilter = {
  0: "Creado",
  1: "Confirmado",
  2: "Confirmado",
  3: "Asignado",
  4: "En ruta Punto A",
  5: "Conductor Confirma el producto",
  6: "En ruta en Destino al cliente",
  7: "Visitado",
  8: "Entregado - BICCI",
  9: "Entregado - BICCI",
  10: "Entregado - BICCI",
  "-1": "Devolución",
  "-2": "Devolución",
  "-3": "Devolución",
  Devolucion: "Devolucion",
  ClaimManagement: "Gestión de Siniestro",
  ClaimManagementIsure: "Gestión de acídente con perdidas Trancito / Operativo",
  LossManagement: "Gestión de Perdida / Extraviado",
  returne: "Folio Retornado al HUB",
};

export const defineFinalState = {
  DEVOLUCION: "Devolucion",
  PERDIDA: "Perdida",
  CANCELADO: "Cancelado",
};

export const translateStatesfalabbela = {
  Terminado: "Entregado",
  Pendiente: "Pendiente",
  1058: "Creado",
  1059: "Sin Asignar",
  1060: "En Ruta",
  1061: "En Ruta",
  99: "Entregado",
  1: "SIN MORADORES",
  1002: "DOMICILIO NO CORRESPONDE",
  1005: "PROD NO ES LO COMPRADO",
  1006: "PROD INCOMPLETO",
  1007: "PROD FALTANTE",
  1008: "PROD DANADO",
  1009: "FUERA DE HORARIO",
  1015: "DESPACHO SALIO ADELANTADO",
  1017: "NO RETIRA NO CUMPL FORMAT",
  1019: "FUERA DE COBERTURA",
  1022: "CLIENTE REALIZARA N/C",
  1023: "PRODUCTO ROBADO",
  1027: "NO RETIRADO",
  1031: "PROD N ENTREGADO X TIEMPO",
  1030: "INCIDENCIA EN LA CALLE",
  1032: "FALTA DE INFORMACIÓN",
  1034: "PROD YA FUE RECIBIDO",
  1036: "DIFICULTAD ACCESO DESTINO",
  1041: "CLIENTE RECHAZA ENVIO",
  1029: "PROCESO DE LI",
  1081: "RETORNADO A ORIGEN",
  1018: "NO CONOCEN AL CLIENTE",
  1038: "CLIENTE CAMBIA DIRECCIÓN",
  1046: "EXTRAVIADO",
  Devolucion: "Devolución",
};

export const globalsStatesCompanys = {
  F: {
    Terminado: "Entregado",
    Pendiente: "Pendiente",
    1058: "Creado",
    1059: "Sin Asignar",
    1060: "En Ruta",
    1061: "En Ruta",
    99: "Entregado",
    1: "SIN MORADORES",
    1002: "DOMICILIO NO CORRESPONDE",
    1005: "PROD NO ES LO COMPRADO",
    1006: "PROD INCOMPLETO",
    1007: "PROD FALTANTE",
    1008: "PROD DANADO",
    1009: "FUERA DE HORARIO",
    1015: "DESPACHO SALIO ADELANTADO",
    1017: "NO RETIRA NO CUMPL FORMAT",
    1019: "FUERA DE COBERTURA",
    1022: "CLIENTE REALIZARA N/C",
    1023: "PRODUCTO ROBADO",
    1027: "NO RETIRADO",
    1031: "PROD N ENTREGADO X TIEMPO",
    1030: "INCIDENCIA EN LA CALLE",
    1032: "FALTA DE INFORMACIÓN",
    1034: "PROD YA FUE RECIBIDO",
    1036: "DIFICULTAD ACCESO DESTINO",
    1041: "CLIENTE RECHAZA ENVIO",
    1029: "PROCESO DE LI",
    1081: "RETORNADO A ORIGEN",
    1018: "NO CONOCEN AL CLIENTE",
    1038: "CLIENTE CAMBIA DIRECCIÓN",
    1046: "EXTRAVIADO",
    Devolucion: "Devolución",
  },
};

export const translateStatesSupport = {
  0: "Creado",
  1: "Asignado",
  2: "Progreso",
  3: "Demora",
  4: "Finalizado",
  5: "Finalizado Rechazado",
  6: "Finalizado Exito",
};

export const translateStatesSelectorBasic = [
  { value: "Pendiente", label: "Pendiente" },
  { value: "Excepcion", label: "Excepcion" },
  // { value: "creado", label: "Creado" },
  // { value: "enruta", label: "En Ruta" },
  // { value: "excepticones", label: "Excepciones" },
  { value: "Terminado", label: "Entregado" },
  { value: "Devolucion", label: "Devolución" },
  { value: "isRequire", label: "Por Actualizar" },
  { value: "Perdida", label: "Perdida" },
  { value: "Cancelado", label: "Cancelado" },
];

export const translateStatesSelectorOptionesBasic = [
  { value: "Creado", label: "Creado" },
  { value: "Excepcion", label: "Excepcion" },
  { value: "Pendiente", label: "Pendiente" },
  { value: "Incidencia", label: "Incidencia" },
  { value: "Perdida", label: "Perdida" },
  { value: "Entregado", label: "Entregado" },
  { value: "Cancelado", label: "Cancelado" },
  { value: "Devolucion", label: "Devolucion" },
];

export const translateStatesfalabbelaSelector = [
  { value: "Terminado", label: "Entregado" },
  { value: "Terminado", label: "Entregado" },
  { value: "Pendiente", label: "Pendiente" },
  { value: 1058, label: "Creado" },
  { value: 1059, label: "Sin Asignar" },
  { value: 1060, label: "En Ruta" },
  { value: 1061, label: "En Ruta" },
  { value: 99, label: "Entregado" },
  { value: 1, label: "SIN MORADORES" },
  { value: 1002, label: "DOMICILIO NO CORRESPONDE" },
  { value: 1005, label: "PROD NO ES LO COMPRADO" },
  { value: 1006, label: "PROD INCOMPLETO" },
  { value: 1007, label: "PROD FALTANTE" },
  { value: 1008, label: "PROD DANADO" },
  { value: 1009, label: "FUERA DE HORARIO" },
  { value: 1015, label: "1015 DESPACHO SALIO ADELANTADO" },
  { value: 1017, label: "NO RETIRA NO CUMPL FORMAT" },
  { value: 1019, label: "FUERA DE COBERTURA" },
  { value: 1022, label: "CLIENTE REALIZARA N/C" },
  { value: 1023, label: "PRODUCTO ROBADO" },
  { value: 1027, label: "NO RETIRADO" },
  { value: 1031, label: "PROD N ENTREGADO X TIEMPO" },
  { value: 1030, label: "INCIDENCIA EN LA CALLE" },
  { value: 1032, label: "FALTA DE INFORMACIÓN" },
  { value: 1034, label: "PROD YA FUE RECIBIDO" },
  { value: 1036, label: "DIFICULTAD ACCESO DESTINO" },
  { value: 1041, label: "CLIENTE RECHAZA ENVIO" },
  { value: 1029, label: "PROCESO DE LI" },
  { value: 1018, label: "NO CONOCEN AL CLIENTE" },
  { value: 1081, label: "RETORNADO A ORIGEN" },
  { value: 1038, label: "CLIENTE CAMBIA DIRECCIÓN" },
  { value: 1046, label: "EXTRAVIADO EN LA CALLE" },
  { value: "Devolucion", label: "Devolución" },
  { value: "devolucion", label: "Devolución" },
];

export const translateStatesSendsFalabbelaSelector = [
  { value: 1, label: "1 SIN MORADORES" },
  { value: 1002, label: "1002 DOMICILIO NO CORRESPONDE" },
  { value: 1007, label: "1007 PROD FALTANTE" },
  { value: 1008, label: "1008 PROD DANADO" },
  { value: 1009, label: "1009 FUERA DE HORARIO" },
  { value: 1015, label: "1015 DESPACHO SALIO ADELANTADO" },
  { value: 1019, label: "1019 FUERA DE COBERTURA" },
  { value: 1022, label: "1022 CLIENTE REALIZARA N/C" },
  { value: 1023, label: "1023 PRODUCTO ROBADO" },
  { value: 1027, label: "1027 NO RETIRADO" },
  { value: 1031, label: "1031 PROD N ENTREGADO X TIEMPO" },
  { value: 1030, label: "1030 INCIDENCIA EN LA CALLE" },
  { value: 1032, label: "1032 FALTA DE INFORMACIÓN" },
  { value: 1034, label: "1034 PROD YA FUE RECIBIDO" },
  { value: 1036, label: "1036 DIFICULTAD ACCESO DESTINO" },
  { value: 1041, label: "1041 CLIENTE RECHAZA ENVIO" },
  { value: 1029, label: "1029 PROCESO DE LI" },
  { alue: 1018, label: "1018 NO CONOCEN AL CLIENTE" },
  // { value: 1081, label: "RETORNADO A ORIGEN" },
  // { value: 1038, label: "CLIENTE CAMBIA DIRECCIÓN" },
  { value: 1046, label: " 1046 EXTRAVIADO EN LA CALLE" },
];

export const cancellationStatesFalabbelaSelector = [ "1007",  "1008", "1023", "1029" , "1046"];
