import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { UserContext } from "../../context/CurrentUserProvider";
import Moment from "moment";
import { OriginsCompanys } from "../../utils/objects/OriginsCompanys";
import { cancellationStatesFalabbelaSelector } from "../../utils/objects/translateStates";

const cacheKey = "bexpress_companyDataCache";
const cacheExpiryKey = "bexpress_companyDataCacheExpiry";
const cacheDuration = 3600 * 10; // 1 hora en milisegundos

function useGetOrdersLocalDataContextByCompany({
  initDateInitial,
  endDateInitial,
}) {
  // console.log("useGetOrdersLocalDataContextByCompany");
  const { userDataContext } = useContext(UserContext);
  const [shouldSubscribe, setShouldSubscribe] = useState(false);
  const [excludePendings, setExcludePendings] = useState(false);
  const listenerRef = useRef(null); // Para guardar la referencia del listener

  const days = 1;
  const daysfinal = 1;
  const [initDate, setInitDate] = useState(
    initDateInitial || Moment(Date.now()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    endDateInitial || Moment(Date.now()).format("YYYY-MM-DD")
  );
  const [mergedOrders, setMergedOrders] = useState([]);
  const [loadingContext, setLoading] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [userAccess, setUserAcess] = useState([]);
  // const [especialRequest, setEspecialRequest] = useState([
  //   "HUB Bicci - CTT de BICCI (E-BIEX)",
  //   "falabella default",
  // ]);

  const companyIDs_Int = useMemo(() => {
    if (userDataContext && userDataContext.userData) {
      const companyCollaborator =
        userDataContext.userData.companyCollaborator || [];
      const userCompanyID = userDataContext.userData.companyID;

      if (userCompanyID && !companyCollaborator.includes(userCompanyID)) {
        companyCollaborator.push(userCompanyID);
      }

      setUserAcess(companyCollaborator);

      return companyCollaborator;
    }
    return [];
  }, [userDataContext]);

  const isAdmin = useMemo(() => {
    return companyIDs_Int.includes("BicciAdmin");
  }, [companyIDs_Int]);

  useEffect(() => {
    const loadAndMergeOrders = async () => {
      setLoading(true);

      // Limpiar órdenes de IndexedDB antes de cargar nuevas
      await clearAllOrdersFromIndexedDB();

      // Obtener datos desde IndexedDB (esto muestra las órdenes ya almacenadas mientras se cargan las nuevas)
      const cachedOrders = await getAllOrdersFromIndexedDB();
      setMergedOrders(cachedOrders);

      let ordersToday = [];
      let ordersPending = [];

      if (isAdmin) {
        let integrations = await getDataFromCache(
          process.env.REACT_APP_SECRETENCRIPT_WEB
        );

        if (
          !integrations ||
          (integrations?.length === 0 && companyIDs_Int.length > 0)
        ) {
          integrations = await getAllConsoleIntegrations(companyIDs_Int);
          await storeDataInCache(
            integrations,
            process.env.REACT_APP_SECRETENCRIPT_WEB
          );
        }

        setCompaniesData(integrations);
        //console.log("Admin", ordersToday);
        // Buscar todas las órdenes activas dentro del rango de fechas
        ordersToday = await findOrderBicci([], initDate, endDate, isAdmin);

        // Buscar todas las órdenes pendientes
        if (!excludePendings) {
          ordersPending = await findOrderBicciPending([], isAdmin);
        } else {
          ordersPending = [];
        }
      } else {
        let integrations = await getDataFromCache(
          process.env.REACT_APP_SECRETENCRIPT_WEB
        );

        if (
          !integrations ||
          (integrations?.length === 0 && companyIDs_Int.length > 0)
        ) {
          integrations = await getConsoleIntegrations(companyIDs_Int);

          await storeDataInCache(
            integrations,
            process.env.REACT_APP_SECRETENCRIPT_WEB
          );
        }

        setCompaniesData(integrations);

        // Buscar órdenes dentro del rango de fechas filtradas por empresa
        ordersToday = await findOrderBicci(
          integrations,
          initDate,
          endDate,
          isAdmin
        );

        // console.log("No admin", ordersToday);
        // Buscar órdenes pendientes filtradas por empresa
        if (!excludePendings) {
          ordersPending = await findOrderBicciPending(integrations, isAdmin);
        } else {
          ordersPending = [];
        }
      }

      // Eliminar duplicados antes de almacenar en IndexedDB
      const allOrders = [...ordersToday, ...ordersPending];
      const uniqueOrders = removeDuplicates(allOrders);

      for (const order of uniqueOrders) {
        await storeOrderInIndexedDB(order);
      }

      // Actualizar mergedOrders con las órdenes unificadas
      const allStoredOrders = await getAllOrdersFromIndexedDB();
      setMergedOrders(allStoredOrders);

      // Iniciar la escucha de cambios en Firebase para sincronizar IndexedDB
      if (shouldSubscribe && companyIDs_Int.length > 0) {
        //  listenStart(companyIDs_Int, isAdmin, initDate, endDate);
      }

      setLoading(false);
    };

    // Limpiar suscripciones anteriores antes de iniciar una nueva
    listenStop();

    // Cargar y fusionar órdenes en función de los cambios
    loadAndMergeOrders();

    return () => {
      listenStop(); // Detener la suscripción al desmontar el componente o al cambiar la fecha
    };
  }, [
    initDate, // Dependencia para el cambio de fecha inicial
    endDate, // Dependencia para el cambio de fecha final
    companyIDs_Int, // Dependencia para las empresas del usuario
    isAdmin, // Dependencia para saber si es admin
    shouldSubscribe, // Si debe suscribirse a las actualizaciones
    excludePendings, // Exclusión de órdenes pendientes
  ]);

  async function generateKey(password) {
    const encodedPassword = new TextEncoder().encode(password);
    const keyMaterial = await crypto.subtle.importKey(
      "raw",
      encodedPassword,
      "PBKDF2",
      false,
      ["deriveKey"]
    );

    return crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: new TextEncoder().encode("unSaltUnico"), // Deberías generar un salt único para cada usuario
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
  }

  // Función para encriptar datos
  async function encryptData(data, password) {
    const encodedData = new TextEncoder().encode(data);
    const key = await generateKey(password);
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      key,
      encodedData
    );

    return {
      encryptedData: Array.from(new Uint8Array(encryptedData)),
      iv: Array.from(iv),
    };
  }

  // Función para desencriptar datos
  async function decryptData(encryptedData, password, iv) {
    const key = await generateKey(password);
    try {
      const decryptedData = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv: new Uint8Array(iv) },
        key,
        new Uint8Array(encryptedData)
      );
      return new TextDecoder().decode(decryptedData);
    } catch (error) {
      throw new Error("Error during decryption: " + error.message);
    }
  }

  // Función para almacenar datos en la caché con encriptación
  const storeDataInCache = async (data, password) => {
    try {
      const { encryptedData, iv } = await encryptData(
        JSON.stringify(data),
        password
      );
      localStorage.setItem(cacheKey, JSON.stringify({ encryptedData, iv }));
      localStorage.setItem(cacheExpiryKey, Date.now() + cacheDuration);
    } catch (error) {
      console.error("Error al almacenar datos en la caché:", error);
    }
  };

  // Función para recuperar datos desde la caché con desencriptación
  const getDataFromCache = async (password) => {
    try {
      const expiry = localStorage.getItem(cacheExpiryKey);
      if (expiry && Date.now() > expiry) {
        // La caché ha expirado
        localStorage.removeItem(cacheKey);
        localStorage.removeItem(cacheExpiryKey);
        return null;
      }

      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        // console.log("Cargando desde caché encriptada Local Context BD");
        const { encryptedData, iv } = JSON.parse(cachedData);
        const decryptedData = await decryptData(encryptedData, password, iv);
        return JSON.parse(decryptedData);
      }
      return null;
    } catch (error) {
      console.error("Error al recuperar datos de la caché:", error);
      return null;
    }
  };

  // Configuración de IndexedDB
  function openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("OrdersDatabase", 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("orders")) {
          db.createObjectStore("orders", { keyPath: "id" });
        }
      };

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  async function storeOrderInIndexedDB(order) {
    const db = await openDatabase();
    const transaction = db.transaction("orders", "readwrite");
    const store = transaction.objectStore("orders");
    store.put(order);
    return transaction.complete;
  }

  async function getAllOrdersFromIndexedDB() {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("orders", "readonly");
      const store = transaction.objectStore("orders");
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  async function deleteOrderFromIndexedDB(orderId) {
    const db = await openDatabase();
    const transaction = db.transaction("orders", "readwrite");
    const store = transaction.objectStore("orders");
    store.delete(orderId);
    return transaction.complete;
  }

  async function clearAllOrdersFromIndexedDB() {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("orders", "readwrite");
      const store = transaction.objectStore("orders");
      const request = store.clear();

      request.onsuccess = () => {
        // console.log("Todos los datos de órdenes han sido eliminados de IndexedDB.");
        resolve();
      };

      request.onerror = (event) => {
        console.error(
          "Error al eliminar datos de IndexedDB:",
          event.target.error
        );
        reject(event.target.error);
      };
    });
  }

  // Función para iniciar la suscripción
  async function listenStart(arrayCompanys, isAdmin, initDate, endDate) {
    const ordersRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
    const allOrders = [];

    const i = new Date(initDate);
    const e = new Date(endDate);

    function initformatDate(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate());
      res.setHours(0);
      res.setMinutes(0);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    function finalformatDate(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate());
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(59);
      res.setMilliseconds(999);
      return res;
    }

    var rangInit = initformatDate(i);
    var rangFinal = finalformatDate(e);

    // Crear una consulta base por el rango de fechas
    let queryOrders = query(
      ordersRef,
      where("updatedAt", ">=", rangInit),
      where("updatedAt", "<=", rangFinal)
    );

    // Obtener las IDs de las compañías del usuario
    const companyIDs = arrayCompanys.map(
      (company) => company.integrationServicesBicciID
    );

    // Suscribirse a la consulta
    listenerRef.current = onSnapshot(queryOrders, async (snapshot) => {
      const orders = [];

      // Procesar los cambios en tiempo real
      snapshot.docChanges().forEach(async (change) => {
        const updatedOrder = {
          id: change.doc.id,
          ...change.doc.data(),
        };

        // Si no es admin, verificar si la orden pertenece a alguna de las compañías del usuario
        if (!isAdmin && !companyIDs.includes(updatedOrder.companyID)) {
          return; // Si la orden no pertenece a las compañías del usuario, se omite
        }

        // Solo almacenar las órdenes que no estén marcadas como "lostDelivery"
        if ( orders.lostDelivery !== true || !cancellationStatesFalabbelaSelector.includes(orders.intState)) {
          if (change.type === "added" || change.type === "modified") {
            orders.push(updatedOrder); // Añadir la orden si es nueva o modificada
          } else if (change.type === "removed") {
            await deleteOrderFromIndexedDB(change.doc.id); // Eliminar la orden si fue removida
          }
        }
      });

      // Eliminar duplicados
      const uniqueOrders = removeDuplicates(orders);

      // console.log(companyIDs);
      // Almacenar solo las órdenes que pertenecen al usuario
      const ordersToStore = uniqueOrders.filter((order) =>
        isAdmin ? true : companyIDs.includes(order.companyID)
      );

      // Depuración: Verificar cuántas órdenes se almacenarán en IndexedDB
      // console.log("Órdenes a almacenar en IndexedDB:", ordersToStore.length);
      // console.log("Órdenes a almacenar en IndexedDB:", ordersToStore);
      // Almacenar las órdenes únicas en IndexedDB
      for (const order of ordersToStore) {
        await storeOrderInIndexedDB(order);
      }

      // Obtener todas las órdenes desde IndexedDB
      const allOrdersFromDB = await getAllOrdersFromIndexedDB();

      // Filtrar las órdenes almacenadas en IndexedDB para que solo se muestren las del usuario
      const filteredOrdersFromDB = allOrdersFromDB.filter((order) =>
        isAdmin ? true : companyIDs.includes(order.companyID)
      );

      // Actualizar el estado con las órdenes combinadas filtradas
      setMergedOrders(filteredOrdersFromDB);
    });
  }

  // Función para detener la suscripción
  function listenStop() {
    if (listenerRef.current) {
      // console.log("Deteniendo la suscripción de cambios de órdenes...");
      listenerRef.current(); // Desuscribirse de onSnapshot
      listenerRef.current = null;
    }
  }

  function removeDuplicates(orders) {
    const ordersMap = {};
    orders.forEach((order) => {
      ordersMap[order.id] = order;
    });
    return Object.values(ordersMap);
  }

  const getAllConsoleIntegrations = async (userAccess) => {
    try {
      if (userAccess.length > 0) {
        const refsCompanys = collection(
          db,
          process.env.REACT_APP_COL_COMPANIES
        );
        const companiesDocs = await getDocs(refsCompanys);

        if (companiesDocs.docs.length > 0) {
          const documentComanies = await Promise.all(
            companiesDocs.docs.map(async (docSnapshot) => {
              let companyData = {
                id: docSnapshot.id,
                ...docSnapshot.data(),
              };

              if (
                companyData.integrationServicesBicciID &&
                companyData.integrationServicesBicciIsActive
              ) {
                const collRefBicci = collection(
                  db,
                  process.env.REACT_APP_COL_BICCI_COMPANIES
                );
                const docRefBicci = doc(
                  collRefBicci,
                  companyData.integrationServicesBicciID
                );
                let documenCompanBicci = await getDoc(docRefBicci);

                if (documenCompanBicci.exists()) {
                  let companiBicci = documenCompanBicci.data();
                  companyData = {
                    ...companyData,
                    ...companiBicci,
                  };
                }
              }

              return companyData;
            })
          );

          return documentComanies;
        } else {
          // console.log("Sin resultados");
          return [];
        }
      } else {
        // console.log("No posee ninguna colaboración");
        return [];
      }
    } catch (error) {
      // console.log("Error al obtener integraciones:", error);
      return [];
    }
  };

  const getConsoleIntegrations = async (userAccess) => {
    // console.log("userAccess", userAccess);
    try {
      if (userAccess.length > 0 && userAccess.length < 10) {
        const refsCompanys = collection(
          db,
          process.env.REACT_APP_COL_COMPANIES
        );
        const q = query(refsCompanys, where("companyRut", "in", userAccess));
        const companiesDocs = await getDocs(q);

        if (companiesDocs.docs.length > 0) {
          const documentComanies = await Promise.all(
            companiesDocs.docs.map(async (document) => {
              let companyData = {
                id: document.id,
                ...document.data(),
              };

              // Si la empresa tiene integraciones activas con BICCI
              if (
                companyData.integrationServicesBicciID &&
                companyData.integrationServicesBicciIsActive
              ) {
                // Obtener los datos de la empresa en la colección BICCI
                const collRefBicci = collection(
                  db,
                  process.env.REACT_APP_COL_BICCI_COMPANIES
                );
                const docRefBicci = doc(
                  collRefBicci,
                  companyData.integrationServicesBicciID
                );
                let documenCompanBicci = await getDoc(docRefBicci);

                if (documenCompanBicci.exists()) {
                  //  console.log(documenCompanBicci.data());
                  let companiBicci = documenCompanBicci.data();
                  // Realizamos el merge de los datos entre la empresa y BICCI
                  companyData = {
                    ...companyData,
                    ...companiBicci, // Merging data from BICCI into companyData
                  };
                }
              }

              return companyData;
            })
          );

          return documentComanies;
        } else {
          //console.log("Sin resultados");
          return [];
        }
      } else {
        // console.log(
        //   "Excede el límite de empresas o no posee ninguna colaboración"
        // );
        return [];
      }
    } catch (error) {
      //console.log("Error al obtener integraciones:", error);
      return [];
    }
  };

  const findOrderBicci = async (arrayCompanys, initDate, endDate, isAdmin) => {
    const allOrders = [];
    const i = new Date(initDate);
    const e = new Date(endDate);

    function initformatDate(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + days);
      res.setHours(0);
      res.setMinutes(0);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }
    function finalformatDate(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + daysfinal);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    var rangInit = initformatDate(i);
    var rangFinal = finalformatDate(e);

    try {
      const ordersDispachtRef1 = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );

      if (isAdmin) {
        const q = query(
          ordersDispachtRef1,
          where("updatedAt", ">=", rangInit),
          where("updatedAt", "<=", rangFinal)
        );
        const querySnapshot1 = await getDocs(q);
        const orders = querySnapshot1.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        allOrders.push(...orders);

      } else {
        try {
          for (let i = 0; i < arrayCompanys.length; i++) {
            if (arrayCompanys[i].integrationServicesBicciID !== "falabella") {
              const q = query(
                ordersDispachtRef1,
                where(
                  "companyID",
                  "==",
                  arrayCompanys[i].integrationServicesBicciID
                ),
                where("updatedAt", ">=", rangInit),
                where("updatedAt", "<=", rangFinal)
              );
              const querySnapshot1 = await getDocs(q);
              const orders = querySnapshot1.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              allOrders.push(...orders);
        
            } else {
              let especialRequest =
                OriginsCompanys[arrayCompanys[i].integrationServicesBicciID];
              for (let n = 0; n < especialRequest.length; n++) {
                //console.log(especialRequest[n]);
                try {
                  const q = query(
                    ordersDispachtRef1,
                    where("placeName", "==", especialRequest[n]),
                    where("updatedAt", ">=", rangInit),
                    where("updatedAt", "<=", rangFinal)
                  );
                  const querySnapshot1 = await getDocs(q);
                  const orders = querySnapshot1.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  allOrders.push(...orders);
          
                } catch (error) {
                  console.log(error);
                }
              }

              console.log("Buscar por Origenes falabella ", arrayCompanys[i]);
            }
          }
        } catch (error) {
          // console.log("Error en las consultas ", arrayCompanys);
        }
      }
    } catch (error) {
      // console.log(`Error fetching orders:`, error);
    }

    return allOrders;
  };

  const findOrderBicciPending = async (arrayCompanys, isAdmin) => {
    const allPendingOrders = [];

    try {
      const ordersDispachtRef1 = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );

      if (isAdmin) {
        const q = query(
          ordersDispachtRef1,
          where("status", ">=", 0),
          where("status", "<", 8)
        );
        const querySnapshot1 = await getDocs(q);
        querySnapshot1.docs.forEach((docOrder) => {
          if (docOrder.data().lostDelivery !== true ) {
            if(!cancellationStatesFalabbelaSelector.includes(docOrder.data().intState)){
              allPendingOrders.push({ id: docOrder.id, ...docOrder.data() });
            }
          }
        });
      } else {
        try {
          for (let i = 0; i < arrayCompanys.length; i++) {
            if (arrayCompanys[i].integrationServicesBicciID !== "falabella") {
              if (arrayCompanys[i].integrationServicesBicciID) {
                try {
                  const q = query(
                    ordersDispachtRef1,
                    where(
                      "companyID",
                      "==",
                      arrayCompanys[i].integrationServicesBicciID
                    ),
                    where("status", ">=", 0),
                    where("status", "<", 8)
                  );
                  const querySnapshot1 = await getDocs(q);

                  querySnapshot1.docs.forEach((docOrder) => {
                    if (docOrder.data().lostDelivery !== true ) {
                      if(!cancellationStatesFalabbelaSelector.includes(docOrder.data().intState)){
                      allPendingOrders.push({
                        id: docOrder.id,
                        ...docOrder.data(),
                      });
                    }}
                  });
                } catch (error) {
                  console.log(error);
                }
              }
            } else {
              let especialRequest =
                OriginsCompanys[arrayCompanys[i].integrationServicesBicciID];
              for (let n = 0; n < especialRequest.length; n++) {
                try {
                  const q = query(
                    ordersDispachtRef1,
                    where("placeName", "==", especialRequest[n]),
                    where("status", ">=", 0),
                    where("status", "<", 8)
                  );
                  const querySnapshot1 = await getDocs(q);
                  querySnapshot1.docs.forEach((docOrder) => {
                    if (docOrder.data().lostDelivery !== true ) {
                      if(!cancellationStatesFalabbelaSelector.includes(docOrder.data().intState)){
                      allPendingOrders.push({
                        id: docOrder.id,
                        ...docOrder.data(),
                      });
                    }}
                  });
                } catch (error) {
                  console.error(error);
                }
              }
            }
          }
        } catch (error) {
          console.log(
            "No se encuentran folios Pendientes para el ID ",
            arrayCompanys
          );
        }
      }
    } catch (error) {
      console.log(`Error fetching pending orders:`, error);
    }

    return allPendingOrders;
  };

  return {
    mergedOrders,
    loadingContext,
    companiesData,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    listenStart,
    listenStop,
    setShouldSubscribe,
    excludePendings,
    setExcludePendings,
  };
}

export default useGetOrdersLocalDataContextByCompany;
