import {
  collection,
  Timestamp,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";

export const requestAccessToCompany = async (
  userId,
  userName,
  email,
  companyId,
  companyName,
  companyRut
) => {
  try {
    const requestDocRef = doc(collection(db, "xpress_userCompanies"));
    const requestId = requestDocRef.id;
    await setDoc(requestDocRef, {
      id: requestId,
      userId,
      userName,
      email,
      companyId,
      companyName,
      companyRut,
      requestIsActive: true,
      isActive: true,
      role: "company_ops",
      status: "Pendiente",
      requestedAt: Timestamp.fromDate(new Date()),
    });

    console.log("Solicitud de acceso enviada con éxito");
  } catch (error) {
    console.error("Error al enviar la solicitud de acceso:", error);
    throw new Error("No se pudo enviar la solicitud de acceso a la empresa");
  }
};

export const getRequestAccessToCompany = async (userId) => {
  try {
    const collRef = collection(db, "xpress_userCompanies");
    const q = query(
      collRef,
      where("userId", "==", userId),
      where("requestIsActive", "==", true)
    );
    const requestUser = await getDocs(q);
    const requestItems = requestUser.docs.map((doc) => doc.data());
    return requestItems;
  } catch (e) {
    console.error("Error al recibir documentos de cliente ");
    console.error(e);
  }
};

export const getRecibeRequestAccessToCompany = async (companyId) => {
  try {
    const collRef = collection(db, "xpress_userCompanies");
    const q = query(
      collRef,
      where("companyRut", "==", companyId),
      where("requestIsActive", "==", true)
    );
    const requestUser = await getDocs(q);
    const requestItems = requestUser.docs.map((doc) => doc.data());
    // console.log(requestItems);
    return requestItems;
  } catch (e) {
    console.error("Error al recibir documentos de empresa ");
    console.error(e);
  }
};
