import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as XLSX from "xlsx";

const SimulationSummary = ({
  simulationSummaryData,
  selectedSimulation,
  generateAd,
  name,
  userId,
  companyID,
  routeType = "abierta",
  waitingTime = 10,
  timeFactor = 30,
}) => {
  const handleExport = () => {
    const totalRutas = simulationSummaryData.length;

    // Hoja "config":
    const configData = [
      // Primera fila: cabeceras de la configuración
      [
        "generateAd",
        "name",
        "userId",
        "companyID",
        "totalRutas",
        "routeType",
        "waitingTime",
        "timeFactor",
      ],
      // Segunda fila: valores de la configuración
      [
        generateAd,
        name,
        userId,
        companyID,
        totalRutas,
        routeType,
        waitingTime,
        timeFactor,
      ],
      [], // Fila vacía a modo de separador
      // Cabeceras de las rutas
      [
        "fecha",
        "ruta",
        "origen",
        "destino",
        "km",
        "tiempo",
        "numParadas",
        "minutosDeEspera",
      ],
      // Datos de cada ruta
      ...simulationSummaryData.map((order) => [
        order.fecha,
        order.ruta,
        order.origen,
        order.destino,
        order.km,
        order.tiempo,
        order.numParadas,
        order.minutosDeEspera,
      ]),
    ];

    // Hoja "data":
    // Cabeceras
    const dataSheet = [
      [
        "fecha",
        "ruta",
        "origen",
        "destino",
        "km",
        "tiempo",
        "numParadas",
        "minutosDeEspera",
      ],
      ...simulationSummaryData.map((order) => [
        order.fecha,
        order.ruta,
        order.origen,
        order.destino,
        order.km,
        order.tiempo,
        order.numParadas,
        order.minutosDeEspera,
      ]),
    ];

    // Crear el libro y las hojas
    const wb = XLSX.utils.book_new();
    const wsConfig = XLSX.utils.aoa_to_sheet(configData);
    const wsData = XLSX.utils.aoa_to_sheet(dataSheet);

    XLSX.utils.book_append_sheet(wb, wsConfig, "config");
    XLSX.utils.book_append_sheet(wb, wsData, "data");

    // Generar el archivo XLSX
    XLSX.writeFile(wb, `Simulacion_${selectedSimulation.name}.xlsx`);
  };

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">
            Resumen de Simulación ({selectedSimulation.name})
          </Typography>
          <Button variant="contained" onClick={handleExport}>
            Exportar
          </Button>
        </Grid>
        {/* Cabeceras en la vista de la app */}
        <Grid
          container
          spacing={1}
          style={{ borderBottom: "2px solid #000", fontWeight: "bold" }}
        >
          <Grid item xs={2}>
            Fecha
          </Grid>
          <Grid item xs={2}>
            Ruta
          </Grid>
          <Grid item xs={2}>
            Origen
          </Grid>
          <Grid item xs={2}>
            Destino
          </Grid>
          <Grid item xs={1}>
            Km
          </Grid>
          <Grid item xs={1}>
            Tiempo
          </Grid>
          <Grid item xs={1}>
            Paradas
          </Grid>
          <Grid item xs={1}>
            Espera (m)
          </Grid>
        </Grid>
        {simulationSummaryData.map((order, index) => (
          <Grid
            container
            key={index}
            spacing={1}
            style={{
              borderBottom: "1px solid #ccc",
              padding: "8px 0",
            }}
          >
            <Grid item xs={2}>
              {order.fecha}
            </Grid>
            <Grid item xs={2}>
              {order.ruta}
            </Grid>
            <Grid item xs={2}>
              {order.origen}
            </Grid>
            <Grid item xs={2}>
              {order.destino}
            </Grid>
            <Grid item xs={1}>
              {order.km} km
            </Grid>
            <Grid item xs={1}>
              {order.tiempo}
            </Grid>
            <Grid item xs={1}>
              {order.numParadas}
            </Grid>
            <Grid item xs={1}>
              {order.minutosDeEspera}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SimulationSummary;
