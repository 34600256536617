const libraries = ["places"];

// SimulationMethodB utilizando Google Directions API
const SimulationMethodB = async (
  data,
  routeType = "abierta",
  waitingTime = 10,
  timeFactor = 30,
  companyID,
  userId
) => {
  console.log("metodo B");
  console.log(data);

  try {
    // Agrupar las órdenes por "driverLicensePlate"
    const routesMap = {};
    data.forEach((order) => {
      const routeName = order.driverLicensePlate || "Ruta_Desconocida";
      if (!routesMap[routeName]) {
        routesMap[routeName] = [];
      }
      routesMap[routeName].push(order);
    });

    const now = new Date();
    const simulationDateStr = now.toISOString().slice(0, 10); // YYYY-MM-DD

    const simulationResults = [];

    // Servicio de direcciones de Google
    const directionsService = new window.google.maps.DirectionsService();

    for (const routeName of Object.keys(routesMap)) {
      const ordersForRoute = routesMap[routeName];
      // Ordenar por fecha de creación (opcional)
      ordersForRoute.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);

      const firstOrder = ordersForRoute[0];
      const origen = firstOrder.fromAddress || "Origen Desconocido";
      const georeferenciaOrigen = {
        lat: firstOrder.fromAddressLocation._lat,
        lng: firstOrder.fromAddressLocation._long,
      };

      const stops = ordersForRoute.map((o) => ({
        lat: o.toAddressLocation._lat,
        lng: o.toAddressLocation._long,
        address: o.toAddress,
      }));

      // Convertir las paradas a waypoints
      const waypoints = stops.map((s) => ({
        location: { lat: s.lat, lng: s.lng },
        stopover: true,
      }));

      const isCerrada = routeType === "cerrada";

      const request = {
        origin: georeferenciaOrigen,
        destination: isCerrada
          ? georeferenciaOrigen
          : waypoints[waypoints.length - 1]?.location || georeferenciaOrigen,
        waypoints: isCerrada ? waypoints : waypoints.slice(0, -1),
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      if (!isCerrada && waypoints.length > 0) {
        request.waypoints = waypoints;
        request.destination = waypoints[waypoints.length - 1].location;
      }

      let directionsResult;
      try {
        // Llamada a la API de Rutas de Google
        directionsResult = await new Promise((resolve, reject) => {
          directionsService.route(request, (result, status) => {
            if (status === "OK") resolve(result);
            else reject(status);
          });
        });
      } catch (status) {
        // Si ocurre un error (ej. ZERO_RESULTS), marcamos la ruta con errorGopoint
        console.error(`Error al obtener ruta para ${routeName}:`, status);
        simulationResults.push({
          fecha: simulationDateStr,
          ruta: routeName,
          codigoID: `${simulationDateStr.replace(/-/g, "")}${routeName}`,
          origen: origen,
          destino: "Sin Ruta",
          numParadas: stops.length,
          minutosDeEspera: waitingTime * stops.length,
          georeferenciaOrigen: georeferenciaOrigen,
          georeferenciaDestino: stops.length
            ? {
                lat: stops[stops.length - 1].lat,
                lng: stops[stops.length - 1].lng,
              }
            : georeferenciaOrigen,
          km: 0,
          tiempo: "0m",
          trayectos: [],
          errorGopoint: true, // Indica que esta ruta tuvo un problema
        });
        // Continuar con la siguiente ruta
        continue;
      }

      // Si no hubo error, procesamos la ruta normalmente
      const route = directionsResult.routes[0];
      const legs = route.legs;
      let totalDistance = 0; // en metros
      let totalDuration = 0; // en segundos

      legs.forEach((leg) => {
        totalDistance += leg.distance.value; // metros
        totalDuration += leg.duration.value; // segundos
      });

      // Convertir distancia a km
      const totalDistanceKm = totalDistance / 1000;

      // Convertir duración a minutos
      const tiempoEstimadoMin = Math.round(totalDuration / 60);

      // Calcular tiempo adicional por paradas
      const numParadas = stops.length;
      const minutosDeEspera = waitingTime * numParadas;
      const tiempoTotalMin = tiempoEstimadoMin + minutosDeEspera;

      const tiempoStr = `${tiempoTotalMin}m`;

      const destino = isCerrada
        ? origen + " (Retorno)"
        : stops[stops.length - 1]?.address || "Destino Desconocido";

      const codigoID = `${simulationDateStr.replace(/-/g, "")}${routeName}`;

      // Trayectos: extraer todos los puntos de la ruta (lat,lng)
      const trayectos = [];
      route.overview_path.forEach((coord) => {
        trayectos.push({ lat: coord.lat(), lng: coord.lng() });
      });

      simulationResults.push({
        fecha: simulationDateStr,
        ruta: routeName,
        codigoID: codigoID,
        origen: origen,
        destino: destino,
        numParadas: numParadas,
        minutosDeEspera: minutosDeEspera,
        georeferenciaOrigen: georeferenciaOrigen,
        georeferenciaDestino: isCerrada
          ? { ...georeferenciaOrigen }
          : {
              lat: stops[stops.length - 1].lat,
              lng: stops[stops.length - 1].lng,
            },
        km: parseFloat(totalDistanceKm.toFixed(2)),
        tiempo: tiempoStr,
        trayectos: trayectos,
      });
    }

    const simulationResult = {
      generateAd: now.toISOString(),
      name: `Google_Simulacion_${now.getTime()}`,
      userId: userId,
      companyID: companyID,
      data: simulationResults,
    };
    console.log(simulationResult);

    return simulationResult;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default SimulationMethodB;
