// Función para calcular la distancia entre dos coordenadas (Haversine)
function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radio de la tierra en km
  const toRad = (deg) => (deg * Math.PI) / 180;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

// Función para encontrar el punto más lejano de un origen dado
function findFarthestPoint(origin, points) {
  let maxDistance = -1;
  let farthestIndex = -1;
  points.forEach((p, i) => {
    const dist = haversineDistance(origin.lat, origin.lng, p.lat, p.lng);
    if (dist > maxDistance) {
      maxDistance = dist;
      farthestIndex = i;
    }
  });
  return farthestIndex;
}

// Heurística simple nearest neighbor para obtener un orden de visita
function nearestNeighborOrder(origin, points) {
  const remaining = points.slice();
  const route = [];
  let current = { ...origin };

  while (remaining.length > 0) {
    let nearestIndex = -1;
    let minDist = Infinity;
    for (let i = 0; i < remaining.length; i++) {
      const dist = haversineDistance(
        current.lat,
        current.lng,
        remaining[i].lat,
        remaining[i].lng
      );
      if (dist < minDist) {
        minDist = dist;
        nearestIndex = i;
      }
    }
    // Agregar el punto más cercano
    route.push(remaining[nearestIndex]);
    current = remaining[nearestIndex];
    remaining.splice(nearestIndex, 1);
  }

  return route;
}

const SimulationMethodA = async (
  data,
  routeType = "abierta",
  waitingTime = 10,
  timeFactor = 30,
  companyID,
  userId
) => {
  console.log("metodo A");
  console.log(data);

  try {
    // Agrupar las órdenes por "driverLicensePlate"
    const routesMap = {};
    data.forEach((order) => {
      const routeName = order.driverLicensePlate || "Ruta_Desconocida";
      if (!routesMap[routeName]) {
        routesMap[routeName] = [];
      }
      routesMap[routeName].push(order);
    });

    const now = new Date();
    const simulationDateStr = now.toISOString().slice(0, 10); // YYYY-MM-DD

    const simulationResults = Object.keys(routesMap).map((routeName, idx) => {
      const ordersForRoute = routesMap[routeName];

      // Ordenar por fecha de creación
      ordersForRoute.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);

      const firstOrder = ordersForRoute[0];
      const origen = firstOrder.fromAddress || "Origen Desconocido";
      const georeferenciaOrigen = {
        lat: firstOrder.fromAddressLocation._lat,
        lng: firstOrder.fromAddressLocation._long,
      };

      // Crear lista de puntos (destinos intermedios) a partir de las órdenes
      // Excepto el primer punto, que se considera el origen
      const stops = ordersForRoute.map((o) => ({
        lat: o.toAddressLocation._lat,
        lng: o.toAddressLocation._long,
        address: o.toAddress,
      }));

      // Dependiendo del tipo de ruta, determinamos cómo optimizar
      let routePoints = [];
      let georeferenciaDestino = { lat: 0, lng: 0 };
      let destino = "Destino Desconocido";

      if (routeType === "abierta") {
        // Ruta abierta: encontrar el punto más lejano del origen
        const farthestIndex = findFarthestPoint(georeferenciaOrigen, stops);
        const farthestPoint = stops[farthestIndex];
        // Quitar el punto más lejano de la lista
        const remainingStops = stops.slice();
        remainingStops.splice(farthestIndex, 1);

        // Ordenar el resto de puntos con nearest neighbor
        const optimizedRoute = nearestNeighborOrder(
          georeferenciaOrigen,
          remainingStops
        );

        // Agregar el punto más lejano al final
        optimizedRoute.push(farthestPoint);
        routePoints = optimizedRoute;

        georeferenciaDestino = {
          lat: farthestPoint.lat,
          lng: farthestPoint.lng,
        };
        destino = farthestPoint.address || "Destino Desconocido";
      } else if (routeType === "cerrada") {
        // Ruta cerrada: nearest neighbor por todos los puntos y volver al origen
        const optimizedRoute = nearestNeighborOrder(georeferenciaOrigen, stops);
        // Volvemos al origen al final
        routePoints = [...optimizedRoute];
        georeferenciaDestino = { ...georeferenciaOrigen };
        destino = origen + " (Retorno)";
      }

      // Calcular distancia total
      let totalDistance = 0;
      let prevPoint = georeferenciaOrigen;
      const trayectos = [georeferenciaOrigen]; // iniciar con el origen
      routePoints.forEach((p) => {
        totalDistance += haversineDistance(
          prevPoint.lat,
          prevPoint.lng,
          p.lat,
          p.lng
        );
        trayectos.push({ lat: p.lat, lng: p.lng });
        prevPoint = p;
      });

      if (routeType === "cerrada") {
        // Volver al origen
        totalDistance += haversineDistance(
          prevPoint.lat,
          prevPoint.lng,
          georeferenciaOrigen.lat,
          georeferenciaOrigen.lng
        );
        trayectos.push(georeferenciaOrigen);
      }

      // Calcular tiempo base en función de la distancia
      // Ejemplo: 30min cada 10 km
      const tiempoEstimadoMin = Math.round((totalDistance / 10) * timeFactor);

      // Incluir tiempo de espera
      // Suponemos 10 minutos de espera por cada parada intermedia
      const numParadas = routePoints.length;
      const minutosDeEspera = waitingTime * numParadas;

      const tiempoTotalMin = tiempoEstimadoMin + minutosDeEspera;
      const tiempoStr = `${tiempoTotalMin}m`;

      const codigoID = `${simulationDateStr.replace(/-/g, "")}${routeName}`;

      return {
        fecha: simulationDateStr,
        ruta: routeName,
        codigoID: codigoID,
        origen: origen,
        destino: destino,
        numParadas: numParadas,
        minutosDeEspera: minutosDeEspera,
        georeferenciaOrigen: georeferenciaOrigen,
        georeferenciaDestino: georeferenciaDestino,
        km: parseFloat(totalDistance.toFixed(2)),
        tiempo: tiempoStr,
        trayectos: trayectos,
      };
    });

    const simulationResult = {
      generateAd: now.toISOString(),
      name: `Simulacion_${now.getTime()}`,
      userId: userId,
      companyID: companyID,
      data: simulationResults,
    };

    // En un caso real, aquí podrías guardar simulationResult en la base de datos.

    return simulationResult;
  } catch (error) {
    console.log(error);
  }
};

export default SimulationMethodA;
