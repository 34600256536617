import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const OrderDataGrid = ({
  data,
  setData,
  handleCreateSimulation,
  handleUploadExcel,
  handleRepairData,
}) => {
  // Definimos las columnas de la DataGrid
  const columns = [
    {
      field: "date",
      headerName: "Fecha",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const dateValue = params.value;
        if (!dateValue) return "Sin fecha";

        let d;
        // Si es un timestamp de Firestore {seconds: number, nanoseconds: number}
        if (dateValue.seconds) {
          d = new Date(dateValue.seconds * 1000);
        } else {
          // Asume que es un string reconocible por Date (YYYY-MM-DD u otro)
          d = new Date(dateValue);
        }

        if (isNaN(d)) {
          return "Fecha inválida"; // Si no puede parsear la fecha
        }
        return d.toLocaleDateString("es-CL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "driverLicensePlate",
      headerName: "Ruta",
      flex: 1,
      editable: true,
    },
    {
      field: "id",
      headerName: "id",
      flex: 1,
      editable: true,
    },
    {
      field: "fromAddress",
      headerName: "Origen",
      flex: 1,
      editable: true,
    },
    {
      field: "toAddress",
      headerName: "Destino",
      flex: 1,
      editable: true,
    },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleRemove(params.row.id)}
        >
          Remover
        </Button>
      ),
    },
  ];

  // Función para remover una orden
  const handleRemove = (id) => {
    const newData = data.filter((row) => row.id !== id);
    setData(newData);
  };

  // Maneja la edición y guarda los cambios en el estado
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const newData = data.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setData(newData);
  };

  return (
    <Box mt={2}>
      <Typography variant="h6">Órdenes a simular:</Typography>
      <Typography>Datos cargados: {data.length} órdenes</Typography>

      <Button variant="contained" component="label" sx={{ mr: 2, mt: 1 }}>
        Agregar más datos
        <input type="file" hidden onChange={handleUploadExcel} />
      </Button>

      <Button variant="outlined" onClick={handleRepairData} sx={{ mt: 1 }}>
        Reparar datos
      </Button>

      <Button
        variant="outlined"
        onClick={handleCreateSimulation}
        sx={{ mt: 1 }}
      >
        Ejecutar Simulación y Guardar
      </Button>

      <Box sx={{ height: "100%", mt: 2 }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onCellEditCommit={handleCellEditCommit}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default OrderDataGrid;
