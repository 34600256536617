import React, { useEffect, useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button } from "@mui/material";
import {
  fetcherRoles,
  fetcherUsers,
} from "../../../../utils/FectcherData/FectcherUser";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ReusableVewData from "../../../../components/ReusableVewData/ReusableVewData";
import ReusableFormEdit from "../../../../components/ReusableFormEdit/ReusableFormEdit";
import { db } from "../../../../firebase";
import DownloadIcon from "@mui/icons-material/Download";
import generateReportByData from "../../../../utils/Reports/generateReportByData";
import { customLocaleText } from "../../../../utils/objects/CustomLocaleTextTables";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", 
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", 
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});

const AdminsCompanies = () => {
  const navigate = useNavigate();
  const [bodyItems, setBodyItems] = useState([]);
  const [rolesOptiones, setRolesOptiones] = useState([]);
  const [render, setRender] = useState(false);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [statusActive, setStatusActive] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, [render]);

  const getData = async () => {
    const users = await fetcherUsers();
    // Ajusta el filtro si es necesario
    const filteredUsers = users.filter((user) => user.roll !== "company_admin");

    setBodyItems(filteredUsers);

    const roles = await fetcherRoles();
    const options = roles.map((role) => {
      return { value: role.id, label: role.id };
    });
    setRolesOptiones(options);
  };

  useEffect(() => {
    upDateTable();
  }, [bodyItems]);

  const handleView = (row) => {
    setInitialValues(row);
    setOpen(true);
  };

  const handleEdit = (row) => {
    navigate(`${row.id}`);
  };

  const handleActivate = async (row) => {
    row.userActive = true;
    setStatusActive(true);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { userActive: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeactivate = async (row) => {
    row.userActive = false;
    setStatusActive(false);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { userActive: false });
    } catch (error) {
      console.log(error);
    }
  };

  const upDateTable = () => {
    const renderActionButtons = (params) => (
      <Box sx={{ minWidth: "200px" }}>
        <IconButton
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
          aria-label="ver"
          onClick={() => handleView(params.row)}
        >
          <VisibilityIcon />
        </IconButton>

        <IconButton
          aria-label="editar"
          onClick={() => handleEdit(params.row)}
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
        >
          <EditIcon />
        </IconButton>

        {params.row.userActive ? (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#21ba45 !important",
            }}
            aria-label="desactivar"
            onClick={() => handleDeactivate(params.row)}
          >
            <ToggleOffIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#1e2158 !important",
            }}
            aria-label="activar"
            onClick={() => handleActivate(params.row)}
          >
            <ToggleOnIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );

    const columnsTable = [
      { field: "id", headerName: "ID", width: 150, editable: true },
      { field: "userName", headerName: "Nombre", width: 150, editable: true },
      { field: "userLastName", headerName: "Apellido", width: 100 },
      { field: "userPhone", headerName: "Teléfono", width: 120, editable: true },
      { field: "email", headerName: "email", width: 200, editable: true },
      { field: "roll", headerName: "roll", width: 150, editable: true },
      { field: "userType", headerName: "userType", width: 100, editable: true },
      { field: "userRut", headerName: "userRut", width: 100, editable: true },
      { field: "userCompany", headerName: "Empresa", width: 150, editable: true },
      { field: "userCompanyRoll", headerName: "Rol en la Empresa", width: 180, editable: true },
      { field: "userCompanyID", headerName: "ID de la Empresa", width: 180, editable: true },
      { field: "userPreferenCont", headerName: "Contacto Preferido", width: 180, editable: true },
      {
        field: "actions",
        headerName: "Acciones",
        width: 300,
        renderCell: renderActionButtons,
        sortable: false,
        filterable: false,
      },
    ];

    setColum(columnsTable);

    const filas = bodyItems.map((item) => ({
      id: item.id,
      userName: item.userName,
      userLastName: item.userLastName,
      email: item.email,
      userPhone: item.userPhone,
      roll: item.roll,
      userType: item.userType,
      userRut: item.userRut,
      userActive: item.userActive || false,
      userCompany: item.userCompany || "",
      userCompanyRoll: item.userCompanyRoll || "",
      userCompanyID: item.userCompanyID || "",
      userPreferenCont: item.userPreferenCont || "",
    }));
    setRows(filas);
  };

  // Campos para ver datos
  const formFields = [
    { variable: "id", label: "id", type: "string" },
    { variable: "userName", label: "userName", type: "string" },
    { variable: "userLastName", label: "userLastName", type: "string" },
    { variable: "email", label: "email", type: "string" },
    {
      variable: "roll",
      label: "roll",
      type: "select",
      options: rolesOptiones,
    },
    { variable: "userType", label: "userType", type: "string" },
    { variable: "userRut", label: "userRut", type: "string" },
    { variable: "userPhone", label: "userPhone", type: "string" },
    { variable: "userCompany", label: "userCompany", type: "string" },
    { variable: "userCompanyRoll", label: "userCompanyRoll", type: "string" },
    { variable: "userCompanyID", label: "userCompanyID", type: "string" },
    { variable: "userPreferenCont", label: "userPreferenCont", type: "string" },
  ];

  // Campos para editar datos
  const formFieldsEdit = [
    { variable: "id", label: "id", type: "string" },
    { variable: "userName", label: "userName", type: "string" },
    { variable: "userLastName", label: "userLastName", type: "string" },
    { variable: "email", label: "email", type: "string" },
    {
      variable: "roll",
      label: "roll",
      type: "select",
      options: rolesOptiones,
    },
    { variable: "userType", label: "userType", type: "string" },
    { variable: "userRut", label: "userRut", type: "string" },
    { variable: "userPhone", label: "userPhone", type: "string" },
    { variable: "userCompany", label: "userCompany", type: "string" },
    { variable: "userCompanyRoll", label: "userCompanyRoll", type: "string" },
    { variable: "userCompanyID", label: "userCompanyID", type: "string" },
    { variable: "userPreferenCont", label: "userPreferenCont", type: "string" },
  ];

  const handleAddItem = (formData) => {
    const itemIndex = rows.findIndex((item) => item.id === formData.id);

    if (itemIndex > -1) {
      const updatedRows = rows.map((item, index) => {
        if (index === itemIndex) {
          return { ...item, ...formData };
        }
        return item;
      });
      setRows(updatedRows);
    } else {
      const newItem = { ...formData };
      if (!newItem.id) newItem.id = rows.length + 1;
      setRows([...rows, newItem]);
    }
  };

  const handleSave = async (formData) => {
    const caracteresValidos =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const generarCadenaAleatoria = (longitud) => {
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    const upsertDocument = async (docData) => {
      let id = docData.id || generarCadenaAleatoria(10);
      if (!docData.id || docData.id === "") {
        docData.id = id;
        docData.servicio_id = id; // Ajuste si requieres igualar este campo
      }

      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, docData);
        console.log("Documento actualizado con éxito.");
      } else {
        await setDoc(docRef, docData);
        console.log("Documento creado con éxito.");
      }

      handleAddItem(docData);
    };

    if (Array.isArray(formData)) {
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
    setOpenEdit(false);
  };

  const createReportByData = () => {
    generateReportByData(bodyItems);
  };

  return (
    <div>
      <Typography variant={"h2"} sx={{ color: "#F8F8F8" }}>
        Gestionar Nuevos Clientes
      </Typography>
      <Button
        variant="outline"
        startIcon={<DownloadIcon />}
        onClick={() => createReportByData(true)}
        sx={{ mb: 2, color: "#f8f8f8" }}
      >
        Exportar
      </Button>
      <ReusableVewData
        open={open}
        handleClose={() => setOpen(false)}
        formFields={formFields}
        initialValues={initialValues}
      />
      <ReusableFormEdit
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        initialValues={initialValues}
      />
      <Box sx={{ backgroundColor: "#F8F8F8" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={50}
          rowsPerPage={[50, 100, 1000]}
          pagination
          localeText={customLocaleText}
          slots={{
            toolbar: GridToolbar,
          }}
          groupingModel={["userName"]}
        ></DataGrid>
      </Box>
    </div>
  );
};

export default AdminsCompanies;
