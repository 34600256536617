import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import {
  Grid,
  LinearProgress,
  Select,
  InputLabel,
  AppBar,
  Toolbar,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Avatar,
  Chip,
  FormControl,
  Box,
  Button,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "react-bootstrap";
import { db } from "../../../firebase.js";
import { collection, getDocs, where, query } from "firebase/firestore";
import PopUpAsignament from "../../../components/PopUpAsignament/PopUpAsignament.jsx";
import { differenceInDays } from "date-fns";
import { MapVectoriales } from "../../../utils/Polygons/VectorialSatelite.js";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames.js";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext.jsx";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../components/CustomDateRangePicker/index.jsx";
import Moment from "moment";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys.jsx";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent.jsx";
import { GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";
import SimulatorOps from "../SimulatorOps/SimulatorOps.jsx";
import MapComponentDistributions from "../../../components/MapComponentDistributions/MapComponentDistributions.jsx";

const libraries = ["places", "drawing", "geometry"];

const DistributionsGoogleMapsPlans = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [companyCollaborator, setCompanyCollaborator] = useState(null);
  const [stateUser, setStateUser] = useState(null);
  const [path, setPath] = useState([]);
  const [viewDrivers, setViewDrivers] = useState(true);
  const [viewDeliverys, setViewDeliverys] = useState(true);
  const [viewExepcions, setViewViewExepcions] = useState(true);
  const [viewPendings, setViewPendings] = useState(true);
  const [viewPoligons, setViewPoligons] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerDriver, setSelectedMarkerDriver] = useState(null);
  const [center, setCenter] = useState({ lat: -33.448891, lng: -70.669266 });
  const [zoom, setZoom] = useState(13);
  const [driver, setDriver] = useState("");
  const [driverFilter, setDriverFilter] = useState([]);
  const [ordersByPoligons, setOrdersByPoligons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState("");
  const [polygons, setPolygons] = useState(null);
  const [polygonsType, setPolygonsType] = useState("redpoligonos");
  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);
  const [ordersForAccions, setOrdersForAccions] = useState([]);
  const [vertical, setServiceVertical] = useState("all");
  const [polygonSelectedName, setPolygonSelectedName] = useState("");
  let infoWindowMap = {};
  const user = useSelector((store) => store.usuario.user);
  const { userdata } = useObtenerDatosdeCliente(user?.uid);
  const { VehiclesNames } = useGetVehiclesNames();
  const [progressValue, setProgressValue] = useState(0);
  const [mensajeAdmin, setMensajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);
  const [companiesOrigin, setCompaniesOrigin] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedOrigins, setSelectedOrigins] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredOrigins, setFilteredOrigins] = useState([]);
  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  const [activeSimulationsOps, setActiveSimulationsOps] = useState(false);
  const [orderSistem, setRemoveOrderSystem] = useState(false);


  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const infoWindowMapRef = useRef({});

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
  } = useOrders({ initDateInitial, endDateInitial });

  const google = window.google;
  const mapRef = useRef();
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Hooks y efectos

  useEffect(() => {
    if (userdata) {
      setStateUser(userdata);
      setCompanyCollaborator(userdata.companyCollaborator);
    }
  }, [userdata]);

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Buscar Drivers
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];
        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );
          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }
        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    if (mergedOrders) {
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  useEffect(() => {
    if (viewMergedOrders) {
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (mergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(mergedOrders);
    }
  }, [ridersDataByCompany]);

  useEffect(() => {
    if (companiesOrigin.length > 0) {
      setFilteredOrigins(companiesOrigin);
    }
  }, [companiesOrigin]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMensajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMensajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      const getOrdersByLocal = (data) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});
        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";
        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [],
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);

        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMensajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMensajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const memoizedData = useMemo(() => {
    if (viewMergedOrders && metricsOps && ridersDataByCompany) {
      return { dataMerge: viewMergedOrders, metricsOps, ridersDataByCompany };
    }
    return null;
  }, [viewMergedOrders, metricsOps, ridersDataByCompany]);

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "phero"];
    let tempView = mergedOrders;

    // Filtro Compañías
    if (selectedCompanies.length > 0) {
      let filteredByCompanies = [];
      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            const tempOrigins = OriginsCompanys[companyID];
            const companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Compañía normal
          const companyFiltered = tempView.filter(
            (items) => items.companyID === companyID
          );
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });
      tempView = filteredByCompanies;
    }

    // Filtro Orígenes
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    setViewMergedOrders(tempView);
  };

  const onPolygonComplete = (polygon) => {
    polygonRef.current = polygon;
    const newCoordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    setPath(newCoordinates);
    polygon.setDraggable(true);
    polygon.setEditable(true);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("dragend", onEdit);
    polygon.addListener("onLoad", onLoad);
    polygon.addListener("onUnmount", onUnmount);
    setSelectedPolygon({
      polygon: polygon,
      coordinates: newCoordinates,
    });
  };

  const onSelectPolygon = (polygon) => {
    polygonRef.current = polygon;
    const coordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }));

    setSelectedPolygon({
      polygon: polygonRef.current,
      coordinates: coordinates,
    });
  };

  async function findPoints() {
    if (!memoizedData?.dataMerge || !google) return;

    const polygon = new google.maps.Polygon({ paths: path });
    const markersInPolygon = memoizedData.dataMerge.filter((marker) => {
      try {
        const markerPosition = new google.maps.LatLng(
          marker?.toAddressLocation?.latitude ||
            marker?.toAddressLocation?._lat,
          marker?.toAddressLocation?.longitude ||
            marker?.toAddressLocation?._long
        );
        return google.maps.geometry.poly.containsLocation(
          markerPosition,
          polygon
        );
      } catch (error) {
        // Si un marker no tiene ubicación válida, lo omitimos
        return false;
      }
    });

    if (markersInPolygon && markersInPolygon.length > 0) {
      setOrdersByPoligons(markersInPolygon);
      const orderIdsWithF = markersInPolygon.map((order) => `F${order.id}`);
      setOrdersForAccions(orderIdsWithF);
    }
  }

  useEffect(() => {
    if (path && path.length > 0) {
      findPoints();
    }
  }, [path]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, []);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const polygonOptions = {
    editable: true,
    draggable: true,
    strokeWeight: 1,
    fillOpacity: 0.3,
    fillColor: "#591e8f",
    strokeColor: "#591e8f",
    strokeOpacity: 0.8,
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    let currentCenter = map.getCenter();
    map.addListener("idle", () => {
      let newCenter = map.getCenter();
      if (
        newCenter.lat() !== currentCenter.lat() ||
        newCenter.lng() !== currentCenter.lng()
      ) {
        setCenter({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
        currentCenter = newCenter;
      }
    });

    let currentZoom = map.getZoom();
    map.addListener("zoom_changed", () => {
      let newZoom = map.getZoom();
      if (newZoom !== currentZoom) {
        setZoom(newZoom);
        currentZoom = newZoom;
      }
    });
  }, []);

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const handleChangeDriver = (e) => {
    setDriver(e.target.value);
    const seleccionRider = memoizedData?.dataMerge?.filter(
      (item) => item.driverID === e.target.value
    );
    setDriverFilter(seleccionRider || []);
  };

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  const setNewDate = () => {
    const createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    const FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });
    setCompaniesOrigin(placeNames);
  };

  const handleChangeService = (e) => {
    setServiceVertical(e.target.value);
  };

  const handleTypePoligons = (e) => {
    setPolygonsType(e.target.value);
  };

  const clearPolygons = () => {
    // Remover polígonos del mapa
    if (polygons) {
      polygons.forEach((polygon) => polygon.setMap(null));
      setPolygons(null);
    }

    // Cerrar todos los infoWindows almacenados
    Object.values(infoWindowMapRef.current).forEach((infoWindow) => {
      if (infoWindow) infoWindow.close();
    });
    infoWindowMapRef.current = {};
  };

  function InfoWindowContent({ name, onClose }) {
    return (
      <div style={{ backgroundColor: "#F8F8F8", padding: 0, margin: 0 }}>
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={onClose}
          // onDelete={onClose}
          // deleteIcon={}
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            cursor: "pointer",
            backgroundColor: "transparent",
          }}
        >
          <CloseIcon sx={{ fontSize: 14, color: "red" }} />
          {name}
        </IconButton>
      </div>
    );
  }

  const loadStaticPolygons = () => {
    if (!isLoaded || !google) return;

    const googlePolygons = MapVectoriales.features.flatMap((feature, index) => {
      if (feature.geometry.type === "Polygon") {
        const paths = feature.geometry.coordinates[0].map(([lng, lat]) => ({
          lat,
          lng,
        }));
        const polygonOptions = {
          paths: paths,
          editable: false,
          draggable: false,
          strokeWeight: 1,
          fillOpacity: 0.3,
          fillColor: "#591e8f",
          strokeColor: "#591e8f",
          strokeOpacity: 0.8,
        };

        const poly = new google.maps.Polygon(polygonOptions);
        poly.setMap(mapRef.current);

        const bounds = new google.maps.LatLngBounds();
        paths.forEach((p) => bounds.extend(p));
        const center = bounds.getCenter();

        // Crear un contenedor para el InfoWindow
        const infoWindowDiv = document.createElement("div");

        // Renderizar el componente React en el contenedor
        ReactDOM.render(
          <InfoWindowContent
            name={feature.properties.Name}
            onClose={() => closeInfoWindow(index)}
          />,
          infoWindowDiv
        );

        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowDiv,
          position: center,
        });
        infoWindow.open(mapRef.current);

        // Guardamos el infoWindow en la referencia
        infoWindowMapRef.current[index] = infoWindow;

        google.maps.event.addListener(poly, "click", () => {
          if (infoWindow.getMap()) {
            infoWindow.close();
          } else {
            infoWindow.open(mapRef.current);
          }
        });

        return poly;
      } else {
        return [];
      }
    });

    setPolygons(googlePolygons);
  };

  const donwLoadPolygons = async () => {
    if (!isLoaded || !google) return;

    const allPolygonsRef = collection(db, process.env.REACT_APP_COL_POLYGONS);
    const qa = query(
      allPolygonsRef,
      where("companyId", "==", props.usuarioStorage.company)
    );
    const dataallPolygonsfind2 = await getDocs(qa);
    let dataPolygonsBase = dataallPolygonsfind2.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const googlePolygons = dataPolygonsBase.map((polygonData) => {
      const polygonOptions = {
        paths: polygonData.coordinates,
        editable: false,
        draggable: false,
        strokeWeight: 1,
        fillOpacity: 0.3,
        fillColor: "#591e8f",
        strokeColor: "#591e8f",
        strokeOpacity: 0.8,
      };

      const poly = new google.maps.Polygon(polygonOptions);
      poly.setMap(mapRef.current);

      google.maps.event.addListener(poly, "click", () => {
        polygonRef.current = poly;
        const coordinates = poly
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
        setSelectedPolygon({
          polygon: poly,
          coordinates: coordinates,
        });
        setPath(coordinates);
      });
      return poly;
    });

    setPolygons(googlePolygons);
  };

  function closeInfoWindow(index) {
    const infoWindow = infoWindowMapRef.current[index];
    if (infoWindow) {
      infoWindow.close();
      delete infoWindowMapRef.current[index];
    }
  }

  useEffect(() => {
    clearPolygons();
    if (viewPoligons) {
      switch (polygonsType) {
        case "redpoligonos":
          loadStaticPolygons();
          break;
        case "comunaspoligonos":
          donwLoadPolygons();
          break;
        default:
          setPolygons(null);
          break;
      }
    }
  }, [viewPoligons, polygonsType, isLoaded]); // Añadimos isLoaded para asegurarnos que la API esté lista

  const renderMarker = (order, index, iconUrl) => {
    return (
      <Marker
        key={index}
        position={{
          lat:
            order?.toAddressLocation?._lat ||
            order?.toAddressLocation?.latitude,
          lng:
            order?.toAddressLocation?._long ||
            order?.toAddressLocation?.longitude,
        }}
        icon={{
          url: iconUrl,
          scaledSize: new google.maps.Size(50, 50),
          anchor: new google.maps.Point(25, 25),
        }}
        onClick={() => {
          setSelectedMarker(order);
        }}
      />
    );
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }

  return memoizedData ? (
    <div>
      <ToolFiltersComponent
        selectedCompanies={selectedCompanies}
        selectedOrigins={selectedOrigins}
        filteredCompanies={filteredCompanies}
        filteredOrigins={filteredOrigins}
        handleChange={handleChange}
        handleOrigins={handleOrigins}
        rangeInputPicker={rangeInputPicker}
        setNewDate={setNewDate}
      />

      {ridersDataByCompany ? (
        <PopUpAsignament
          opemPopUpAsignament={opemPopUpAsignament}
          setOpemPopUpAsignament={setOpemPopUpAsignament}
          ordersForAccions={ordersForAccions}
          ridersDataByCompany={ridersDataByCompany}
        />
      ) : null}

      <AppBar position="static" sx={{ background: "#fff" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={activeSimulationsOps}
                onChange={() => setActiveSimulationsOps(!activeSimulationsOps)}
                aria-label="login switch"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#591e8f",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#591e8f",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  color: activeSimulationsOps ? "#591e8f" : "#000",
                  textTransform: activeSimulationsOps ? "uppercase" : "none",
                }}
              >
                Modo
              </Typography>
            }
          />
        </FormGroup>
        {activeSimulationsOps ? (
          <>
            <Grid item xs={12} md={12} lg={6}>
              <Box p={1}>
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, color: "#030303", textAlign: "Left" }}
                >
                  SIMULAR OPERACÍON:
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={orderSistem}
                        onChange={() => setRemoveOrderSystem(!orderSistem)}
                        aria-label="login switch"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#591e8f",
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#591e8f",
                            },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: orderSistem ? "#591e8f" : "#000",
                          textTransform: orderSistem ? "uppercase" : "none",
                        }}
                      >
                        Utilizar data del sistema
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid item xs={12} md={12} lg={6}>
              <Box p={1} sx={{ display: "flex", flexDirection: "row" }}>
                {driverFilter.length ? (
                  <Typography sx={{ ml: 2, color: "#030303" }}>
                    Carga de Conductor: {driverFilter.length}{" "}
                  </Typography>
                ) : null}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, color: "#030303", textAlign: "right" }}
                >
                  <Typography>
                    Folios en consulta: {memoizedData?.dataMerge?.length}
                  </Typography>
                </Typography>
              </Box>

              <Box p={1}>
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, color: "#030303", textAlign: "Left" }}
                >
                  MAPA DE DISTRIBUCIÓN:
                </Typography>
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Box p={1} sx={{ display: "flex" }}>
                  <FormControl sx={{ minWidth: "30%", width: "30%", m: 1 }}>
                    <InputLabel
                      id="select-label-servicio"
                      sx={{ color: "#000" }}
                    >
                      Servicio
                    </InputLabel>
                    <Select
                      labelId="select-label-servicio"
                      id="demo-simple-select"
                      value={vertical}
                      label="Servicio"
                      onChange={handleChangeService}
                      sx={{ backgroundColor: "#f8f8f8" }}
                    >
                      <MenuItem key={"all"} value={"all"}>
                        Todos los servicios
                      </MenuItem>
                      <MenuItem key={"sd"} value={"SD"}>
                        SAME DAY
                      </MenuItem>
                      <MenuItem key={"hd"} value={"HD"}>
                        HOME DELIVERY
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: "30%", width: "30%", m: 1 }}>
                    <InputLabel id="select-label-poligonos">
                      Polígonos
                    </InputLabel>
                    <Select
                      labelId="select-label-poligonos"
                      id="select-poligonos-type"
                      label="Tipo de polígonos"
                      onChange={handleTypePoligons}
                      sx={{ backgroundColor: "#f8f8f8" }}
                      value={polygonsType}
                    >
                      <MenuItem value={"redpoligonos"}>
                        Polígonos Bicci
                      </MenuItem>
                      <MenuItem value={"comunaspoligonos"}>
                        Polígonos por Comunas
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: "30%", width: "30%", m: 1 }}>
                    <InputLabel id="select-label-driver">Conductor</InputLabel>
                    <Select
                      labelId="select-label-driver"
                      id="demo-simple-select-driver"
                      value={driver}
                      label="Conductor"
                      onChange={handleChangeDriver}
                      sx={{ backgroundColor: "#f8f8f8" }}
                    >
                      {memoizedData.metricsOps.map((item, index) => (
                        <MenuItem key={index} value={item.driverID}>
                          {item.driverName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography type={"span"}>
                    <Button onClick={() => setDriverFilter([])}>
                      Limpiar filtros
                    </Button>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid p={1} container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Box p={1} sx={{ display: "flex" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={viewDrivers}
                          onChange={() => setViewDrivers(!viewDrivers)}
                          aria-label="login switch"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#591e8f",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#591e8f",
                              },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: viewDrivers ? "#591e8f" : "#000",
                            textTransform: viewDrivers ? "uppercase" : "none",
                          }}
                        >
                          Riders
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={viewPendings}
                          onChange={() => setViewPendings(!viewPendings)}
                          aria-label="login switch"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#591e8f",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#591e8f",
                              },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: viewPendings ? "#591e8f" : "#000",
                            textTransform: viewPendings ? "uppercase" : "none",
                          }}
                        >
                          Pendientes
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={viewDeliverys}
                          onChange={() => setViewDeliverys(!viewDeliverys)}
                          aria-label="login switch"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#591e8f",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#591e8f",
                              },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: viewDeliverys ? "#591e8f" : "#000",
                            textTransform: viewDeliverys ? "uppercase" : "none",
                          }}
                        >
                          Entregados
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={viewExepcions}
                          onChange={() => setViewViewExepcions(!viewExepcions)}
                          aria-label="login switch"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#591e8f",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#591e8f",
                              },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: viewExepcions ? "#591e8f" : "#000",
                            textTransform: viewExepcions ? "uppercase" : "none",
                          }}
                        >
                          Excepciones
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={viewPoligons}
                          onChange={() => setViewPoligons(!viewPoligons)}
                          aria-label="login switch"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#591e8f",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#591e8f",
                              },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: viewPoligons ? "#591e8f" : "#000",
                            textTransform: viewPoligons ? "uppercase" : "none",
                          }}
                        >
                          Poligonos
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={6}></Grid>
            </Grid>
          </>
        )}
      </AppBar>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "450px",
          backgroundColor: "#F8F8F8",
          padding: "0px",
        }}
      >
        {selectedMarkerDriver ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
              borderBottomRightRadius: "10px",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <Avatar
              sx={{ bgcolor: "#f8f8f8", width: 70, height: 70 }}
              src={
                selectedMarkerDriver.avatar ? selectedMarkerDriver.avatar : ""
              }
              alt=""
            />
            <Typography>{selectedMarkerDriver.name}</Typography>
            <Typography>{selectedMarkerDriver.id}</Typography>
            <Typography>{selectedMarkerDriver.email}</Typography>
            <Typography>{selectedMarkerDriver.dni}</Typography>
            <Typography>{selectedMarkerDriver.bicciNumber}</Typography>
            <Typography>{selectedMarkerDriver.phone}</Typography>
            <Typography>Carga : {driverFilter.length} </Typography>
            <Button onClick={() => setSelectedMarkerDriver(null)}>
              Cerrar
            </Button>
          </Box>
        ) : null}

        {ordersByPoligons && ordersByPoligons.length > 0 ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              maxHeight: "80%",
              overflowY: "scroll",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              {polygonSelectedName}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              Selección en polígono {ordersByPoligons.length} Folios
            </Typography>
            {ordersByPoligons.map((item) => (
              <Stack key={item.id} sx={{ with: "100%" }}>
                <Chip
                  size="small"
                  label={<Typography>ID: {item.id}</Typography>}
                  sx={{ mb: 1 }}
                />
              </Stack>
            ))}

            <Button onClick={() => setOrdersByPoligons([])}>Cerrar</Button>
            <Button onClick={() => setOpemPopUpAsignament(true)}>
              Asignar Bicci Riders
            </Button>
            <Button onClick={() => console.log("asignar")}>
              Asignar Bicci Express
            </Button>
          </Box>
        ) : null}

        {activeSimulationsOps ? (
          <>
            {
              <SimulatorOps
                initialData={orderSistem ? memoizedData.dataMerge : []}
                companyID={user && user.company ? user.company : "noCompanyID"}
                userId={user && user.uid ? user.uid : "noUserId"}
              ></SimulatorOps>
            }
          </>
        ) : (
          <>
            {/* Render del mapa */}
            {memoizedData && driverFilter.length === 0 ? (
             <>
          
              <MapComponentDistributions
                  ridersDataByCompany={ridersDataByCompany}
                  dataMerge={memoizedData.dataMerge}
                  companyID={user && user.company ? user.company : "noCompanyID"}
                  isLoaded={isLoaded}
                  center={center}
                  zoom={zoom}
                ></MapComponentDistributions>
                </>
            ) : (
              <> Mapa de ruteo </>
            )}
          </>
        )}
      </Box>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default DistributionsGoogleMapsPlans;

// Hook personalizado para obtener datos de cliente (usado en el código)
function useObtenerDatosdeCliente(uid) {
  const [userdata, setUserdata] = useState(null);
  useEffect(() => {
    // Aquí iría la lógica para obtener datos del cliente.
    // Este hook no se incluyó en el snippet original, se asume ya implementado.
    // setUserdata(datosObtenidos);
  }, [uid]);
  return { userdata };
}
