import React, { useState, useEffect } from "react";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import * as XLSX from "xlsx";
import OrderDataGrid from "./OrderDataGrid";
import SimulationMethodA from "./utils/SimulationMethodA";
import SimulationMethodB from "./utils/SimulationMethodB";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import SimulationSummary from "./SimulationSummary/SimulationSummary";
import SimulationMap from "./SimulationMap/SimulationMap";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const SimulatorOps = ({ initialData, companyID, userId }) => {
  const [ordersData, setOrdersData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0); // 0: Origen de datos, 1: Ver simulaciones
  const [simulationTab, setSimulationTab] = useState(0); // 0: Resumen, 1: Gráficas
  const [availableSimulations, setAvailableSimulations] = useState([]);
  const [selectedSimulation, setSelectedSimulation] = useState(null);
  const [simulationSummaryData, setSimulationSummaryData] = useState([]);
  const [simulationMethod, setSimulationMethod] = useState("A");

  // Variables de configuración del simulador
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [routeType, setRouteType] = useState("abierta"); // "abierta" o "cerrada"
  const [waitingTime, setWaitingTime] = useState(10); // minutos de espera por parada
  const [timeFactor, setTimeFactor] = useState(30); // minutos cada 10km (ejemplo)

  useEffect(() => {
    setOrdersData(initialData);
  }, [initialData]);

  useEffect(() => {
    loadAvailableSimulations();
  }, []);

  const loadAvailableSimulations = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, "xpress_routesSimulations")
      );
      const sims = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        sims.push({
          id: data.id,
          name: data.name,
          date: data.generateAd.slice(0, 10),
          data: data.data,
        });
      });
      setAvailableSimulations(sims);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleUploadExcel(event) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Lee las filas crudas
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: true,
      });
      const headers = jsonData[0];
      const rows = jsonData.slice(1);

      const dataFromExcel = rows.map((row) => {
        const obj = {};
        headers.forEach((h, i) => {
          if (h) {
            obj[h] = row[i];
          }
        });

        // Parsear la fecha directamente como texto
        let createdAtLocal;
        if (obj.createdAt) {
          const date = new Date(obj.createdAt);
          if (!isNaN(date.getTime())) {
            // Fecha válida
            const ms = date.getTime();
            const seconds = Math.floor(ms / 1000);
            const nanoseconds = (ms % 1000) * 1000000;
            createdAtLocal = { seconds, nanoseconds };
          } else {
            // Fecha inválida, usar fecha actual
            const now = Date.now();
            createdAtLocal = {
              seconds: Math.floor(now / 1000),
              nanoseconds: (now % 1000) * 1000000,
            };
          }
        } else {
          // No hay fecha, usar actual
          const now = Date.now();
          createdAtLocal = {
            seconds: Math.floor(now / 1000),
            nanoseconds: (now % 1000) * 1000000,
          };
        }

        return {
          id: obj.id || "",
          toAddress: obj.toAddress || "",
          fromAddress: obj.fromAddress || "",
          driverLicensePlate: obj.driverLicensePlate || "Ruta_Desconocida",
          toAddressLocation: {
            _lat: parseFloat(obj.toAddressLocationLat),
            _long: parseFloat(obj.toAddressLocationLng),
          },
          fromAddressLocation: {
            _lat: parseFloat(obj.fromAddressLat),
            _long: parseFloat(obj.fromAddressLng),
          },
          date: createdAtLocal,
          createdAt: createdAtLocal,
          placeName: obj.placeName || "",
        };
      });

      setOrdersData((prev) => [...prev, ...dataFromExcel]);
    };

    reader.readAsArrayBuffer(file);
  }

  const apiDireccionlive = async (address, country) => {
    try {
      const results = await getGeocode({
        address: `${address}, ${country}`,
      });
      console.log(address);
      if (results) {
        // Loop through the address components and log them to the console
        for (let i = 0; i < results[0].address_components.length; i++) {
          let elementAdress = results[0].address_components[i];

          switch (elementAdress["types"][0]) {
            case "street_number":
              console.log(elementAdress.long_name);
              break;
            case "route":
              console.log(elementAdress.long_name);
              break;
            case "locality":
              console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_3":
              //console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_2":
              //console.log(elementAdress.long_name);
              //console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_1":
              //console.log(elementAdress.long_name);
              //console.log(elementAdress.long_name);
              break;
            case "country":
              console.log(elementAdress.long_name);
              //console.log(elementAdress.long_name);
              break;
            case "postal_code":
              ////console.log(elementAdress.long_name);
              break;
            default:
              break;
          }
        }
      } else {
        console.log("error en la dirección :", address);
      }

      const { lat, lng } = await getLatLng(results[0]);
      console.log(lat + "  " + lng);
      return { lat, lng };
    } catch (error) {
      console.log(error);
      return { lat: 0, lng: 0 };
    }
  };

  async function handleRepairData() {
    try {
      const repairedData = await Promise.all(
        ordersData.map(async (order) => {
          if (
            !order.toAddressLocation ||
            typeof order.toAddressLocation._lat !== "number" ||
            typeof order.toAddressLocation._long !== "number" ||
            Number.isNaN(order.toAddressLocation._lat) ||
            Number.isNaN(order.toAddressLocation._long)
          ) {
            if (order.toAddress && order.toAddress.trim() !== "") {
              const newLocation = await apiDireccionlive(
                order.toAddress,
                "chile"
              ).catch((error) => {
                console.error("Error fetching geocode:", error);
                return null;
              });
              if (newLocation) {
                return {
                  ...order,
                  toAddressLocation: {
                    _lat: newLocation.lat,
                    _long: newLocation.lng,
                  },
                };
              }
            }
          } else {
            console.log("hay otro error ?");
          }
          return order; // Mantener la orden original si no necesita cambios
        })
      );
      console.log("Repaired Data:", repairedData);
      setOrdersData(repairedData);
    } catch (error) {
      console.log(error);
    }
  }

  const handleOpenConfigDialog = () => {
    setConfigDialogOpen(true);
  };

  const handleCloseConfigDialog = () => {
    setConfigDialogOpen(false);
  };

  const handleCreateSimulation = async () => {
    // Aquí abrimos el diálogo para configurar la simulación antes de ejecutarla
    handleOpenConfigDialog();
  };

  const handleRunSimulation = async () => {
    // Cerrar el diálogo
    handleCloseConfigDialog();

    let simulationResult = null;
    if (simulationMethod === "A") {
      simulationResult = await SimulationMethodA(
        ordersData,
        routeType,
        waitingTime,
        timeFactor,
        companyID,
        userId
      );
    } else if (simulationMethod === "B") {
      // Por ahora ignoramos routeType, waitingTime, timeFactor en method B
      simulationResult = await SimulationMethodB(
        ordersData,
        routeType,
        waitingTime,
        timeFactor,
        companyID,
        userId
      );
    }

    // Guardar en DB o lo que necesites
    // await saveSimulationToDB(simulationResult);
    console.log(simulationResult);
    if (simulationResult) {
      try {
        const colRef = collection(db, "xpress_routesSimulations");
        // Aquí addDoc genera un ID único automáticamente
        const docRef = await addDoc(colRef, simulationResult);

        // docRef.id es el ID asignado por Firestore
        // Si deseas guardar ese ID dentro del documento:
        await updateDoc(docRef, { id: docRef.id });
      } catch (error) {
        console.log(error);
      }
    }
    // Ahora el documento en Firestore tiene un campo 'id' con el mismo ID

    // Refrescar la lista de simulaciones
    loadAvailableSimulations();
  };

  const handleSelectSimulation = (sim) => {
    setSelectedSimulation(sim);
    setSimulationSummaryData(sim.data);
  };

  const handlePlayRoute = (routeData) => {
    // Lógica para animar la ruta
  };

  return (
    <Box>
      <Typography variant="h5">Simulador de Operaciones</Typography>
      {/* Seleccionar método de simulación */}
      <FormControl sx={{ minWidth: 200, mt: 2 }}>
        <InputLabel id="simulation-method-label">
          Método de Simulación
        </InputLabel>
        <Select
          labelId="simulation-method-label"
          id="simulation-method-select"
          value={simulationMethod}
          label="Método de Simulación"
          onChange={(e) => setSimulationMethod(e.target.value)}
        >
          <MenuItem value="A">Método A</MenuItem>
          <MenuItem value="B">Método B</MenuItem>
        </Select>
      </FormControl>

      <Tabs value={tabIndex} onChange={(e, val) => setTabIndex(val)}>
        <Tab label="Origen de Datos" />
        <Tab label="Ver Simulaciones" />
      </Tabs>

      {tabIndex === 0 && (
        <Box p={2}>
          <OrderDataGrid
            data={ordersData}
            setData={setOrdersData}
            handleCreateSimulation={handleCreateSimulation}
            handleUploadExcel={handleUploadExcel}
            handleRepairData={handleRepairData}
          />
        </Box>
      )}

      {tabIndex === 1 && (
        <Box p={2}>
          <Typography>Simulaciones Disponibles:</Typography>
          <Grid container spacing={2}>
            {availableSimulations.map((sim) => (
              <Grid item key={sim.id}>
                <Button
                  variant="contained"
                  onClick={() => handleSelectSimulation(sim)}
                >
                  {sim.name} ({sim.date})
                </Button>
              </Grid>
            ))}
          </Grid>

          {selectedSimulation && (
            <Box mt={2}>
              <Tabs
                value={simulationTab}
                onChange={(e, val) => setSimulationTab(val)}
              >
                <Tab label="Resumen de Simulación" />
                <Tab label="Ver Gráficas" />
              </Tabs>

              {simulationTab === 0 && (
                <SimulationSummary
                  simulationSummaryData={simulationSummaryData}
                  selectedSimulation={selectedSimulation}
                />
              )}

              {simulationTab === 1 && (
                <SimulationMap
                  simulationSummaryData={simulationSummaryData}
                  selectedSimulation={selectedSimulation}
                ></SimulationMap>
              )}
            </Box>
          )}
        </Box>
      )}

      {/* Dialogo de configuración de simulación */}
      <Dialog open={configDialogOpen} onClose={handleCloseConfigDialog}>
        <DialogTitle>Configuración de Simulación</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="route-type-label">Tipo de Ruta</InputLabel>
            <Select
              labelId="route-type-label"
              id="route-type-select"
              value={routeType}
              label="Tipo de Ruta"
              onChange={(e) => setRouteType(e.target.value)}
            >
              <MenuItem value="abierta">Abierta</MenuItem>
              <MenuItem value="cerrada">Cerrada</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Minutos de Espera por Parada"
            type="number"
            fullWidth
            value={waitingTime}
            onChange={(e) => setWaitingTime(Number(e.target.value))}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Minutos por cada 10km"
            type="number"
            fullWidth
            value={timeFactor}
            onChange={(e) => setTimeFactor(Number(e.target.value))}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfigDialog}>Cancelar</Button>
          <Button variant="contained" onClick={handleRunSimulation}>
            Ejecutar Simulación
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SimulatorOps;
