import React, { useRef, useState, useCallback } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  GoogleMap,
  useLoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "750px",
};

// Centro inicial del mapa (ajusta según tus necesidades)
const center = { lat: -33.45, lng: -70.66 };
const libraries = ["places", "drawing", "geometry"];

const SimulationMap = ({ simulationSummaryData, selectedSimulation }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [routesInProgress, setRoutesInProgress] = useState([]);
  // routesInProgress: array de objetos {routeIndex, pointsDrawn: [], intervalId}

  const mapRef = useRef(null);

  const onLoadMap = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const drawSegmentOnMap = (routeIndex, segmentPoint) => {
    // Actualizar el estado para agregar nuevos puntos dibujados
    setRoutesInProgress((prev) =>
      prev.map((r) =>
        r.routeIndex === routeIndex
          ? { ...r, pointsDrawn: [...r.pointsDrawn, segmentPoint] }
          : r
      )
    );
  };

  const handlePlayRoute = (order) => {
    return new Promise((resolve) => {
      const routeIndex = simulationSummaryData.indexOf(order);
      const trayectos = order.trayectos;

      // Si ya se está reproduciendo esta ruta, la ignoramos
      if (routesInProgress.some((r) => r.routeIndex === routeIndex)) {
        resolve();
        return;
      }

      // Inicializar esta ruta con 1 punto (el inicio)
      setRoutesInProgress((prev) => [
        ...prev,
        { routeIndex, pointsDrawn: [trayectos[0]], intervalId: null },
      ]);

      let currentIndex = 1;
      const interval = 100; // Ajusta este valor para cambiar la velocidad (ms)

      const intervalId = setInterval(() => {
        if (currentIndex >= trayectos.length) {
          // Terminamos la ruta
          clearInterval(intervalId);
          setRoutesInProgress((prev) =>
            prev.map((r) =>
              r.routeIndex === routeIndex ? { ...r, intervalId: null } : r
            )
          );
          resolve();
          return;
        }

        drawSegmentOnMap(routeIndex, trayectos[currentIndex]);
        currentIndex++;
      }, interval);

      // Guardar el intervalId en el estado
      setRoutesInProgress((prev) =>
        prev.map((r) =>
          r.routeIndex === routeIndex ? { ...r, intervalId } : r
        )
      );
    });
  };

  const handlePlayAllSequential = async () => {
    for (let i = 0; i < simulationSummaryData.length; i++) {
      await handlePlayRoute(simulationSummaryData[i]);
    }
    console.log("Todas las rutas reproducidas secuencialmente.");
  };

  const handlePlayAllSimultaneous = async () => {
    const promises = simulationSummaryData.map((order) =>
      handlePlayRoute(order)
    );
    await Promise.all(promises);
    console.log("Todas las rutas reproducidas simultáneamente.");
  };

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <Box p={2}>
      <Typography variant="h6">Gráficas</Typography>
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePlayAllSequential}
          style={{ marginRight: 8 }}
        >
          Play All (Sequential)
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePlayAllSimultaneous}
        >
          Play All (Simultaneous)
        </Button>
      </Box>
      <Box display="flex">
        <Box
          sx={{
            width: "20%",
            height: "90vh", // Altura completa de la pantalla
            overflowY: "auto", // Scroll vertical si hay desbordamiento
            padding: "16px",
            backgroundColor: "#f8f8f8", // Fondo opcional
            boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)", // Sombra opcional
          }}
        >
          {simulationSummaryData.map((order, index) => (
            <Box key={index} mb={2}>
              <Typography>
                {order.fecha} - {order.ruta} - {order.km} km -{" "}
                {order.numParadas} Paradas
              </Typography>
              <Button
                variant="contained"
                onClick={() => handlePlayRoute(order)}
              >
                Play
              </Button>
            </Box>
          ))}
        </Box>
        <Box width="100%" height="100%">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={12}
            onLoad={onLoadMap}
          >
            {/* Dibujar las rutas en progreso */}
            {routesInProgress.map((r) => {
              const routeData = simulationSummaryData[r.routeIndex];
              return (
                <React.Fragment key={r.routeIndex}>
                  <Polyline
                    path={r.pointsDrawn}
                    options={{
                      strokeColor: "#5048E5",
                      strokeOpacity: 1.0,
                      strokeWeight: 4,
                    }}
                  />
                  {/* Agregar pines en las paradas */}
                </React.Fragment>
              );
            })}
          </GoogleMap>
        </Box>
      </Box>
    </Box>
  );
};

export default SimulationMap;
